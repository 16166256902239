import {Ess} from '../../../../../interfaces/ess/Ess';
import {forceUnit, forceUnitType} from "../../../../../service/ForceUnit";
import {Grid} from "@mui/material";
import CardRow from "../CardRow";
import {formatDateTime} from "../../../../../service/FormatDateTime";

interface propsInterface {
    ess: Ess;
}

export default function EssDetails(props: propsInterface) {
    return (
        <Grid container>
            <CardRow label="Mise en route">
                {formatDateTime(props.ess.operatingDate)}
            </CardRow>
            <CardRow label="Capacité nominale (KWh)">
                {forceUnit(props.ess.ratedCapacity, forceUnitType.kwh)}
            </CardRow>
            <CardRow label="Puissance nominale (KWh)">
                {forceUnit(props.ess.ratedPower, forceUnitType.kwh)}
            </CardRow>
            <CardRow label="Serial number">
                {props.ess.serialNumber}
            </CardRow>
        </Grid>
    );
}