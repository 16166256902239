import authenticatedApi from "../AuthenticatedApi";
import { CheckpointBlock } from "../../interfaces/control/CheckpointBlock";
import { CheckpointResult } from "../../interfaces/control/Checkpoint";
import { CheckpointExecute, CheckpointExecuteTypeEnum } from "../../interfaces/control/CheckpointExecute";
import {UploadFile} from 'antd/es/upload';
import { REACT_APP_API_CONTROL_URL } from "../../config";
import Plant from "../../interfaces/control/Plant";
import {PlantFilters} from "../../interfaces/control/PlantFilters";
import {StatusCodes} from "http-status-codes";

const baseUrlApiControl = REACT_APP_API_CONTROL_URL;
const apiControlCheckpoints = 'checkpoints';
const apiControlCheckpointResult = 'checkpoint_result';
const apiControlCheckpointsExecute = 'checkpoints_execute';
const apiControlCheckpointExecute = 'checkpoint_execute';
const apiControlCheckpointExecuteSoutirage = 'checkpoint_execute/soutirage';
const apiControlCheckpointBlockResultComment = 'checkpoint_result_block';
const apiControlPlants = 'plants';


const apiGateway = {
    apiControl: {
        checkpointsBlocks: async function(plantId: number): Promise<Array<CheckpointBlock>> {
            const url = `${baseUrlApiControl}/${apiControlCheckpoints}/${plantId}`;
            return await authenticatedApi.get(url, false)
                .then(response => response.json())
                .then(data => data);
        },
        checkpointsExecuteAll: async function(plantId: number): Promise<Array<CheckpointBlock>> {
            const checkpointExecuteValue = {} as CheckpointExecute;
            checkpointExecuteValue.plantId = plantId;
            checkpointExecuteValue.type = CheckpointExecuteTypeEnum.ALL;
            const url = `${baseUrlApiControl}/${apiControlCheckpointsExecute}`;
            return await authenticatedApi.post(url, checkpointExecuteValue, false)
                .then(response => response.json())
                .then(data => data);
        },
        checkpointResultExecute: async function(checkpointExecute: CheckpointExecute): Promise<CheckpointResult> {
            const url = `${baseUrlApiControl}/${apiControlCheckpointExecute}`;
            return await authenticatedApi.post(url, checkpointExecute, false)
                .then(response => response.json())
                .then(data => data);
        },
        checkpointExecuteSoutirage: async function(plantId: number, file: UploadFile): Promise<CheckpointResult> {
            const url = `${baseUrlApiControl}/${apiControlCheckpointExecuteSoutirage}`;
            const formData = new FormData();
            formData.append('plant_id', plantId.toString());
            formData.append('file', file.originFileObj!);
            return await authenticatedApi.postFormData(url, formData)
                .then(response => response.json())
                .then(data => data);
        },
        updateCheckpointResult: async function(chechpointResult: CheckpointResult): Promise<CheckpointResult> {
            const url = `${baseUrlApiControl}/${apiControlCheckpointResult}`;
            return await authenticatedApi.patch(url, chechpointResult, false)
                .then(response => response.json())
                .then(data => data);
        },
        updateCheckpointBlockResultComment: async function(checkpointBlock: CheckpointBlock): Promise<CheckpointBlock> {
            const url = `${baseUrlApiControl}/${apiControlCheckpointBlockResultComment}/${checkpointBlock.id}/comment`;
            return await authenticatedApi.patch(url, {comment: checkpointBlock.comment}, false)
                .then(response => response.json())
                .then(data => data);
        },
        getPlants: async function(filters: PlantFilters): Promise<Plant[]> {
            let url = `${baseUrlApiControl}/${apiControlPlants}`;
            const getParams = [];
            if (filters.search !== '') {
                getParams.push(`search=${filters.search}`);
            }
            if (filters.begin && filters.begin !== '') {
                getParams.push(`begin=${filters.begin}`);
            }
            if (filters.end && filters.end !== '') {
                getParams.push(`end=${filters.end}`);
            }
            if (filters.agency !== -1) {
                getParams.push(`agency_id=${filters.agency}`);
            }
            if (filters.status !== 'ALL') {
                getParams.push(`status=${filters.status}`);
            }
            if (getParams.length > 0) {
                url += `?${getParams.join('&')}`;
            }
            const response =  await authenticatedApi.get(url);
            if (response.status !== StatusCodes.OK) {
                throw new Error('Une erreur est survenue lors de la récupération des installations à controler');

            }
            return response.json();
        },
        getPlant: async function(id: number): Promise<Plant> {
            const url = `${baseUrlApiControl}/${apiControlPlants}/${id}`;
            const response =  await authenticatedApi.get(url);
            return response.json();
        }
    }
};

export default apiGateway;