import {Grid} from "@mui/material";
import CardRow from "../CardRow";
import {formatDateTime} from "../../../../../service/FormatDateTime";
import {Plant} from "../../../../../interfaces/Plant";
import TagList from "../../../../../component/TagList";
import * as React from "react";

interface propsInterface {
    plant: Plant;
}

export default function PlantDetails(props: propsInterface) {
    return (
        <Grid container>
            <CardRow label="Identifiant" data-testid="idPlant">
                {props.plant.id}
            </CardRow>
            <CardRow label="Mise en route">
                {formatDateTime(props.plant.operationDate)}
            </CardRow>
            <CardRow label="Bridage activé">
                {props.plant.restrictedPower ? "Oui" : (props.plant.restrictedPower === false ? "Non" : "")}
            </CardRow>
            {props.plant.restrictedPower &&
            <CardRow label="Valeur de bridage">
                {props.plant.restrictedValue !== null ? props.plant.restrictedValue / 1000 + " kW" : ""}
            </CardRow>}
            <CardRow label={"Tags"}>
                {props.plant?.tags !== undefined ? <TagList
                    tags={props.plant.tags.map((tag) => ({label: tag}))}
                    limitTags={2}
                /> : <>-</>}
            </CardRow>
        </Grid>
    );
}