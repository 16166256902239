import Plant from "../interfaces/control/Plant";
import PlantStatusEnum from "../enums/PlantStatusEnum";
import PlantStatusDescriptionEnum from "../enums/PlantStatusDescriptionEnum";
import Tooltip from '@mui/material/Tooltip';
import {useTheme} from "@mui/material/styles";

type PlantStatusTagProps = {
    status: Plant['status'];
}

const PlantStatusTag = ({status}: PlantStatusTagProps) => {
    const theme = useTheme();

    const statuses = {
        [PlantStatusEnum.TO_CONTROL]: {
            "color": theme.palette.toControl,
            "description": PlantStatusDescriptionEnum.TO_CONTROL,
        },
        [PlantStatusEnum.COMPLIANT]: {
            "color": theme.palette.compliant,
            "description": PlantStatusDescriptionEnum.COMPLIANT,
        },
        [PlantStatusEnum.NOT_COMPLIANT]: {
            "color": theme.palette.notCompliant,
            "description": PlantStatusDescriptionEnum.NOT_COMPLIANT,
        },
        [PlantStatusEnum.CONTROL_IN_PROGRESS]: {
            "color": theme.palette.controlInProgress,
            "description": PlantStatusDescriptionEnum.CONTROL_IN_PROGRESS,
        },
    };
    return (
        <Tooltip title={statuses[status].description}>
            <div style={{width: '16px', height: '16px', borderRadius: '16px', backgroundColor: statuses[status].color}}></div>
        </Tooltip>
    )
}

export default PlantStatusTag;