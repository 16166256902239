
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { CurrentAlarmItem } from '../../../../interfaces/CurrentAlarmItem';
import CountAlarmChart from './CountAlarmChart';
import {formatAlarmReference} from "../../../../service/FormatAlarm";


type CountAlarmByLevelChartsProps = {
  value: Array<CurrentAlarmItem>;
}


type CurrentAlarmItemRenamed = CurrentAlarmItem & {
  alarmTypeReferenceRenamed: string;
}

function renameAlarmReference(item: CurrentAlarmItem): CurrentAlarmItemRenamed {
  return {...item, alarmTypeReferenceRenamed: formatAlarmReference(item.alarmTypeReference)}
}


export default function CountAlarmByLevelCharts(props: CountAlarmByLevelChartsProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Grid item xs={12} >
      <Grid container >
        <Grid item xs={12} md={6} style={{ display: "flex", paddingRight: (isMobile) ? '0px' : '10px' }}>
          <CountAlarmChart value={props.value.filter(x => x.alarmTypeLevel === 30).map(x => renameAlarmReference(x))} title={"Alarmes critiques"} />
        </Grid>
        <Grid item xs={12} md={6} style={{ display: "flex", paddingLeft: (isMobile) ? '0px' : '10px' }}>
          <CountAlarmChart value={props.value.filter(x => x.alarmTypeLevel === 0).map(x => {return {...x, alarmTypeReferenceRenamed: x.alarmTypeReference}})} title={"Equipements déconnectés"} />
        </Grid>
        <Grid item xs={12} md={6} style={{ display: "flex", paddingRight: (isMobile) ? '0px' : '10px' }}>
          <CountAlarmChart value={props.value.filter(x => x.alarmTypeLevel === 20).map(x => renameAlarmReference(x))} title={"Alarmes"}/>
        </Grid>
        <Grid item xs={12} md={6} style={{ display: "flex", paddingLeft: (isMobile) ? '0px' : '10px' }}>
          <CountAlarmChart value={props.value.filter(x => x.alarmTypeLevel === 10).map(x => renameAlarmReference(x))} title={"Evènements"}/>
        </Grid>
      </Grid>
    </Grid>
  )
}
