import React, { RefObject, ReactNode, useImperativeHandle, ForwardRefRenderFunction, useEffect, useState} from 'react';

import { SubmitHandler, useForm} from 'react-hook-form';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormHelperText from '@mui/material/FormHelperText';

import SelectAgencies from '../../../component/form/SelectAgencies';

import User, {URI_AGENCY} from "./model";
import { User as UserInterface, RolesTypes } from '../../../interfaces/User';

import apiV2 from '../../../service/api/ApiV2';
import ToastService from '../../../service/ToastService';
import { Agency } from '../../../interfaces/Agency';
import {StatusCodes} from "http-status-codes";


function getMenuItemRoles(): ReactNode {
    const menu: JSX.Element[] = []
    Object.values(RolesTypes).forEach((value, index) => {
        menu.push(<MenuItem key={index} value={Object.keys(RolesTypes)[index]}>{value}</MenuItem>)
    })
    return menu
}

type UserFormProp = {
    formRef: RefObject<UserFormHandle>;
    setIsValidInput: (value: boolean) => void;
    handleUpdateRowUser: (user: UserInterface) => void;
    userModified: UserInterface | null;
};

type UserFormHandle = {
    submitForm: () => void;
};

let counter = 0;

const UserForm: ForwardRefRenderFunction<UserFormHandle, UserFormProp> = (props) => {
    const { formRef, setIsValidInput } = props;
    const [agenciesRequiredErrors, setAgenciesRequiredErrors] = useState<boolean>(false);
    const [agenciesSelected, setAgenciesSelected] = useState<Agency[]>([]);
    const { register, handleSubmit, trigger, setValue, setError, watch, formState: { errors } } = useForm<User>(
        {
          defaultValues: {
            id: null,
            firstname: "",
            lastname: "",
            role: Object.keys(RolesTypes)[1],
            email: "",
            phone: "",
            agencies: []
          }
        });

    const isSelectedAgencies = () => watch('agencies').length > 0;

    useEffect(() => {
        register('agencies', {
            validate: isSelectedAgencies
          })
        }, [isSelectedAgencies, register])

    useEffect(() => {
        setAgenciesRequiredErrors(false);
        /**
         * TODO: on doit refactor le CreateUser coté APIV2 car il y a contradiction sur les attributs
         * lastName != lastname attendu pour créer un utilisateur (à changer à la prochaine évol des users)
         * @see ClientForm & ClientInterface
         */
        if (props.userModified && counter < 1){
            setValue('id', props.userModified.id);
            setValue('lastname', props.userModified.lastName);
            setValue('firstname', props.userModified.firstName);
            setValue('email', props.userModified.email);
            setValue('phone', props.userModified.phone);
            setValue('role',  Object.keys(RolesTypes)[Object.keys(RolesTypes).indexOf(props.userModified.role)]);
            if (props.userModified.agencies.length > 0) handleChangeSelectAgencies(props.userModified.agencies)
            trigger(['lastname', 'firstname', 'email', 'phone', 'agencies']);
            counter++;
        }
    }, [props, setValue, trigger]);

    /**
     * A la desctruction du composant on remet le counter à 0
     */
    useEffect(() => {
        return () => {
            counter = 0
        };
      }, []);

    const serverSideValidForm = async (response: Response, errorMessage: string) => {
        if(response.status === StatusCodes.BAD_REQUEST){
            const content = await response.json();
            const errorsServerSide = JSON.parse(content.detail)
            if (errorsServerSide.input === 'email'){
                setError('email', {
                    type: "server",
                    message: errorsServerSide.message,
                });
            }
            ToastService.error(errorMessage);
        }else{
            ToastService.error(errorMessage);
        }
    }

    const onSubmit: SubmitHandler<User> = data => {
        data.agencies = agenciesSelected.map(agency => URI_AGENCY + agency.id);
        if(props.userModified){
            /**
             * on doit passer par une interface temporaire car la création d un user est faite en lowerCase
             * mais la récupération d un User se fait en CamelCase via l api v2 (à modifier)
             */
            const userUpdated: UserInterface = {
                id: (data.id) ? data.id : -1,
                firstName: data.firstname,
                lastName: data.lastname,
                email: data.email,
                phone: data.phone,
                role: data.role,
                agencies: agenciesSelected
            };
            apiV2.updateUser(data).then((response) => {
                if(response.ok){
                    ToastService.success('L\'utilisateur ' + data.firstname + ' ' + data.lastname + ' vient d\'être modifié');
                    props.handleUpdateRowUser(userUpdated)
                }else{
                    ToastService.error('Erreur durant la modification de l\'utilisateur');
                }
            });
        }else{
            apiV2.createUser(data).then((response) => {
                if(response.ok){
                    setIsValidInput(true)
                    ToastService.success('Un nouvel utilisateur vient d\'être créé');
                }else{
                    setIsValidInput(false)
                    serverSideValidForm(response, 'Erreur durant la création de l\'utilisateur')
                }
            });
        }
    }

    const getDefaultMenuItemRole = () => {
        const roleIndex = (props.userModified) ? props.userModified.role : Object.keys(RolesTypes)[1]
        return Object.keys(RolesTypes)[Object.keys(RolesTypes).indexOf(roleIndex)]
    }

    const handleChangeSelectAgencies = (agencies: Agency[]) => {
        setValue('agencies', agencies.map(agencies => URI_AGENCY + agencies.id))
        setAgenciesSelected(agencies)
        setAgenciesRequiredErrors(!isSelectedAgencies());
        trigger(['agencies']);
    };

    // permet d appeler le submit form depuis le composant parent
    useImperativeHandle(formRef, () => ({
        submitForm() {
            setAgenciesRequiredErrors(!isSelectedAgencies());
            handleSubmit(onSubmit)();
        },
    }));

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2} direction="row" >
                    <TextField
                        id="UserFirstnameInput"
                        type="text"
                        variant='outlined'
                        color='primary'
                        label="Prénom"
                        required
                        error={!!(errors.firstname) }
                        {...register("firstname", {
                                required: "Le prénom est obligatoire",
                                minLength: {
                                    value: 2,
                                    message: "Le prénom doit contenir au moins 2 caractères"
                                },
                                maxLength: {
                                    value: 50,
                                    message: "Le prénom ne peut dépasser 50 caractères"
                                }
                            })
                        }
                        fullWidth
                        helperText={(errors.firstname) ? errors.firstname.message : null}
                    />
                    <TextField
                        id="UserLastnameInput"
                        type="text"
                        variant='outlined'
                        color='primary'
                        label="Nom"
                        required
                        error={!!(errors.lastname) }
                        {...register("lastname", {
                                required: "Le nom est obligatoire",
                                minLength: {
                                    value: 2,
                                    message: "Le nom doit contenir au moins 2 caractères"
                                },
                                maxLength: {
                                    value: 50,
                                    message: "Le nom ne peut dépasser 50 caractères"
                                }
                            })
                        }
                        fullWidth
                        helperText={(errors.lastname) ? errors.lastname.message : null}
                    />
                </Stack>
                <TextField
                    id="UserEmailInput"
                    type="email"
                    variant='outlined'
                    color='primary'
                    label="Email"
                    required
                    error={!!(errors.email) }
                    {...register("email", {
                            required: "L'email est obligatoire",
                            pattern: {
                                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                message: "L'email n'est pas valide"
                            }
                        })
                    }
                    fullWidth
                    helperText={(errors.email) ? errors.email.message : null}
                    sx={{mb: 4}}
                />
                <TextField
                    id="UserPhoneInput"
                    type="text"
                    variant='outlined'
                    color='primary'
                    label="Numéro de téléphone"
                    error={!!(errors.phone) }
                    {...register("phone", {
                        onChange: (e) => {
                            const phone = e.target.value;
                            // On autorise uniquement des chiffres, et on souhaite représenter
                            // le numéro de téléphone avec des espaces tous les 2 caractères
                            // pour en faciliter la lecture
                            setValue('phone', phone.replace(/[^0-9]/gi, '').replace(/(.{2})/g, '$1 ').trimEnd())
                            trigger('phone');
                            },
                            pattern: {
                                value: /^[0-9\s]*$/i,
                                message: 'Le numéro ne doit contenir que des chiffres',
                            },
                            minLength: {
                                value: 14,
                                message: "Le numéro de téléphone doit contenir 10 chiffres"
                            },
                            maxLength: {
                                value: 14, // format base xx xx xx
                                message: "Le numéro de téléphone doit contenir 10 chiffres"
                            }
                        })
                    }
                    helperText={(errors.phone) ? errors.phone.message : null}
                    fullWidth
                    sx={{mb: 4}}
                />
                <FormControl fullWidth>
                    <InputLabel id="selectRroles-Label" required>Rôle</InputLabel>
                    <Select
                        labelId="selectRolesLabel"
                        id="selectRoles"
                        error={!!(errors.role) }
                        {...register("role", {
                                required: "Le rôle est obligatoire",
                            })
                        }
                        label="Rôle"
                        required
                        defaultValue={getDefaultMenuItemRole()}
                        displayEmpty
                        color='primary'
                    >
                        { getMenuItemRoles() }
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <SelectAgencies
                        required={true}
                        preloadAgencies={props.userModified?.agencies}
                        onChange={handleChangeSelectAgencies}
                        errors={agenciesRequiredErrors}
                    />
                    {agenciesRequiredErrors &&
                        <FormHelperText error>Au moins une agence doit etre selectionnée</FormHelperText>
                    }
                </FormControl>
            </form>
        </React.Fragment>
    )
};

export default React.forwardRef(UserForm);
