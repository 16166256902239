import * as React from "react";
import {useEffect, useRef, useState} from "react";

import {Autocomplete, AutocompleteProps, AutocompleteRenderInputParams, createFilterOptions} from "@mui/material";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {getPlantTags} from "../../store/form/plantTags.store";
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";


const filter = createFilterOptions<OptionType>();

type OptionType = {
    label: string;
    value: string;
    create?: boolean;
};

type AutocompletePlantTagProps = {
    preSelectedTags?: string[];
    onChange: (newValue: OptionType[]) => void;
    isCreatable?: boolean;
    size?: AutocompleteProps<any, any, any, any>['size'];
}

const RENDER_TAGS_LIMIT_IN_CREATION = 10;
const RENDER_TAGS_LIMIT = 2;

const AutocompletePlantTag = ({isCreatable = false, preSelectedTags, onChange, size}: AutocompletePlantTagProps) => {
    const [selected, setSelected] = useState<OptionType[]>([]);
    const [options, setOptions] = useState<OptionType[]>([]);
    const [search, setSearch] = useState<string>("");
    const [hasError, setHasError] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { data: tags, error } = useAppSelector(state => state.plantTags);
    // Permet de ne pas déclencher l'event 'onChange' lors de l'initialisation du composant :
    const isMounted = useRef(false);

    const resetAll = () => {
        setSelected([]);
        setSearch("");
    }

    const searchPlantSubject = useRef(new Subject<string>()).current;

    useEffect(() => {
        const subscription = searchPlantSubject.pipe(debounceTime(300)).subscribe(
            (searchValue) => dispatch(getPlantTags(searchValue))
        );
        return () => subscription.unsubscribe();
    }, [dispatch, searchPlantSubject]);

    useEffect(() => {
        searchPlantSubject.next(search);
    }, [search, searchPlantSubject]);

    useEffect(() => {
        if (error !== null) {
            setOptions([]);
        } else {
            const newOptions = tags.map((tag) => ({label: tag.label, value: tag.label}));
            if (preSelectedTags !== undefined && preSelectedTags.length > 0) {
                newOptions.concat(preSelectedTags.map( (selectedTag) => ({label: selectedTag, value: selectedTag})));
            }
            setOptions(newOptions.sort((a, b) => a.label.localeCompare(b.label)));
        }
    }, [preSelectedTags, tags, error]);

    useEffect(() => {
        if (isMounted.current) {
            onChange(selected);
        } else {
            isMounted.current = true;
        }
    }, [selected]);

    useEffect(() => {
        if (preSelectedTags === undefined) return;
        setSelected(
            preSelectedTags.map((tag) => ({label: tag, value: tag}))
        );
    }, [preSelectedTags]);

    return (
        <Autocomplete
            size={size}
            fullWidth
            value={selected}
            multiple
            title={selected.map((option) => option.label).join(', ')}
            inputValue={search}
            onInputChange={(event, value, reason) => {
                if (reason === 'clear') resetAll();
            }}
            onChange={(event, newValue, reason, details) => {
                if (reason === 'clear') {
                    resetAll();
                    return;
                }
                // details.option c'est l'option qui a déclenché l'événement
                // Si l'option a son attribut "create" défini et qu'il vaut true, alors on ajoute la valeur aux options sélectionnées
                if (details?.option.create) {
                    if (details.option.value.includes(",")) {
                        // On passe en erreur si l'utilisateur a cliqué  sur l'ajout d'un tag contenant une virgule
                        setHasError(true);
                        return;
                    }
                    setSelected([...selected, {
                        label: details.option.value,
                        value: details.option.value,
                    }]);
                } else {
                    setSelected(newValue.filter(value => {
                        // On est obligé de tester le type de la valeur car la props 'freeSolo' de l'autocomplete est
                        // activée, ce qui fait changer le type de la variable 'newValue'
                        return typeof value !== "string"
                    }));
                }
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            filterOptions={(options, params): OptionType[] => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                if (isCreatable) {
                    const isExisting = options.concat(selected).some((option) => inputValue === option.label);
                    if (inputValue !== "" && inputValue.length > 2 && !isExisting) {
                        // On ajoute une option qui suggère l'ajout d'un tag avec la valeur saisie dans l'input :
                        filtered.splice(0, 0, {
                            value: inputValue,
                            label: `Ajouter "${inputValue}"`,
                            create: true,
                        });
                    }
                }
                return filtered;
            }}
            selectOnFocus
            handleHomeEndKeys
            id="tags"
            data-cy={'autocomplete-tags'}
            options={options}
            renderOption={(props, option) => (
                    <li {...props} key={props.key}>{option.label}</li>
            )}
            renderTags={(value, getTagProps) => {
                const numTags = value.length;
                // On n'affiche pas le même nombre de chips affichés selon le mode de l'autocomplete
                // puisque le mode 'création' permet d'ajouter des tags directement depuis l'input
                const limitTags = isCreatable ? RENDER_TAGS_LIMIT_IN_CREATION : RENDER_TAGS_LIMIT;
                return (
                    <Box>
                        {value.slice(0, limitTags).map((option, index) => (
                            <Chip
                                size={size}
                                style={{ maxWidth: '100%' }}
                                {...getTagProps({ index })}
                                key={index}
                                label={option.label}
                            />
                        ))}
                        {numTags > limitTags && ` +${numTags - limitTags}`}
                    </Box>
                );
            }}
            disableCloseOnSelect
            freeSolo={isCreatable} // nécessaire pour pouvoir ajouter des valeurs depuis l'input via l'event 'OnChange' de l'Autocomplete
            renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                    {...params}
                    value={search}
                    error={hasError}
                    onChange={(e) => {
                        if (hasError) setHasError(false); // Reset des erreurs dès que l'utilisateur modifie l'input
                        setSearch(e.target.value);
                    }}
                    label="Tags"
                    helperText={hasError ? 'Les tags ne peuvent pas contenir de virgule.' :
                        isCreatable ? "Saisissez 3 caractères minimum pour ajouter un tag." : ""
                    }
                />
            )}
        />
    );
}

export default AutocompletePlantTag;
