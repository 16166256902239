import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import * as React from "react";
import Tag from "../interfaces/Tag";
import {ComponentProps} from "react";

type TagListProps = {
    tags: Tag[];
    size?: ComponentProps<typeof Chip>['size'];
    limitTags?: number;
}

const TagList = ({tags, size = 'small', limitTags = 2}: TagListProps) => {

    const numTags = tags.length;

    if (numTags === 0) {
        return <Box>-</Box>
    }

    return (
        <Box title={tags.map( (tag) => tag.label ).join(',')}>
            {tags.slice(0, limitTags).map((tag, index) => (
                <Chip
                    style={{ maxWidth: '100%' }}
                    key={index}
                    label={tag.label}
                    size={size}
                />
            ))}
            {numTags > limitTags && ` +${numTags - limitTags}`}
        </Box>
    )
}

export default TagList