import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import { CriteriaValueReponse } from './model'

interface PropsInterface {
  valid: boolean
  label: string
  values: CriteriaValueReponse[]
}

export default function Criterion(props: PropsInterface): React.ReactElement {
  return <Box
    sx={{
      display: 'flex',
    }}
  >
    <Box
      sx={{
        padding: 1,
      }}
    >
      {props.valid &&
        <DoneIcon color={'success'} fontSize={'large'} />
      }
      {!props.valid &&
        <CloseIcon color={'error'} fontSize={'large'} />
      }
    </Box>
    <Box sx={{
      padding: 1
    }}>
      <Typography variant="h6" gutterBottom fontWeight={'bold'}>
        {props.label}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {props.values.map(criteriaValue => {
          return `${criteriaValue.parameter_name}: ${criteriaValue.value} `
        })}
      </Typography>
    </Box>
  </Box>
}
