
import { ChartOptions } from 'chart.js';
import Card from '@mui/material/Card';
import { ElevationChart } from './ElevationChart';
import { PowerChart } from './PowerChart';
import { WindChart } from './WindChart';
import { useTheme } from '@mui/material';
import { alpha } from "@mui/material";
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import { Dayjs } from 'dayjs';
import { Plant } from '../../../../interfaces/Plant';
import { ImmersionHeater } from '../../../../interfaces/ImmersionHeater';
import { Tracker } from '../../../../interfaces/Tracker';
import { SnowChart } from './SnowChart';
import { TrackerEventCharts } from './TrackerEventCharts';
import { ImmersionHeaterEventCharts } from './ImmersionHeaterEventCharts';
import { AzimuthChart } from './AzimuthChart';
import { Ess } from "../../../../interfaces/ess/Ess";
import { MeterEventChart } from './MeterEventChart';
import { Meter } from '../../../../interfaces/Meter';
import { PlantEventChart } from './PlantEventChart';


type SynchronizedChartProps = {
  id: string;
  plant: Plant;
  immersionHeaters: ImmersionHeater[];
  ess: Ess[];
  trackers: Tracker[];
  meter: Meter | null;
  startDate: Dayjs;
  endDate: Dayjs;
}

export function SynchronizedChart(props: SynchronizedChartProps) {
  const theme = useTheme();

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    resizeDelay: 0,
    spanGaps: false,
    elements: {
      point: {
        radius: 0
      }
    },
    interaction: {
      mode: 'x'
    },
    layout: {
      padding: {
        left: 0,
        right: 5, // Force a padding right to fix right-alignment of all charts
        // FIXME: Find a better way to align all charts. Especially the TrackerEventChart that has a padding right for no
        // FIXME: reason.
        top: 0,
        bottom: 0
      }
    },
    plugins: {
      tooltip: {
        intersect: false,
        mode: 'nearest',
        axis: 'x',
      },
      legend: {
        display: false
      },
      title: {
        position: 'left',
        padding: 0,
      },
      crosshair: {
        line: {
          color: theme.palette.primary.main,
          width: 1
        },
        sync: {
          enabled: true,
          group: 1,
        },
        zoom: {
          zoomboxBackgroundColor: alpha(theme.palette.primary.main, 0.3),
          zoomboxBorderColor: alpha(theme.palette.primary.main, 0.3),
        }
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          tooltipFormat: 'dddd D MMM YYYY, HH:mm',
          displayFormats: {
            hour: 'HH:mm',
            minute: 'mm',
            second: 'ss'
          },
        },
        ticks: {
          major: {
            enabled: true
          },
          font: (context) => {
            return { weight: context.tick?.major ? 'bold' : null, size: context.tick?.major ? 12 : 10, visible: false }
          },
        },
        grid: {
          color: (theme.palette.mode === 'dark') ? '#383838' : '#f3f1f1'
        }
      },
      y: {
        afterFit(scale) {
          scale.width = 50;
        },
        border: {
          display: true
        },
        grid: {
          color: (theme.palette.mode === 'dark') ?  '#383838' : '#f3f1f1'
        },
      }
    },
  };

  return (
    <div>
      <Card className='scada-card' style={{marginTop: '0px'}}>
        <PlantEventChart
          options={options}
          plant={props.plant}
          startDate={props.startDate}
          endDate={props.endDate}
        />
      </Card>
      <Card className='scada-card' style={{marginTop: '0px'}}>
        <TrackerEventCharts
          options={options}
          plant={props.plant}
          trackers={props.trackers}
          startDate={props.startDate}
          endDate={props.endDate}
        />
      </Card>
      <Card className='scada-card' style={{display: (props.immersionHeaters.length === 0) ? 'none': ''}}>
        <ImmersionHeaterEventCharts
          options={options}
          plant={props.plant}
          immersionHeaters={props.immersionHeaters}
          startDate={props.startDate}
          endDate={props.endDate}
        />
      </Card>
      {
        props.meter !== null &&
        <Card className='scada-card' style={{marginTop: '0px'}}>
            <MeterEventChart
              options={options}
              plant={props.plant}
              meter={props.meter}
              startDate={props.startDate}
              endDate={props.endDate}
            />
        </Card>
      }
      <Card className='scada-card'>
        <ElevationChart
            options={options}
            plant={props.plant}
            trackers={props.trackers}
            startDate={props.startDate}
            endDate={props.endDate}
        />
      </Card>
      <Card className='scada-card'>
        <AzimuthChart
            options={options}
            plant={props.plant}
            trackers={props.trackers}
            startDate={props.startDate}
            endDate={props.endDate}
        />
      </Card>
      <Card className='scada-card'>
        <PowerChart
          options={options}
          plant={props.plant}
          trackers={props.trackers}
          immersionHeaters={props.immersionHeaters}
          startDate={props.startDate}
          endDate={props.endDate}
          />
      </Card>
      <Card className='scada-card'>
        <WindChart
          options={options}
          plant={props.plant}
          trackers={props.trackers}
          startDate={props.startDate}
          endDate={props.endDate}
        />
      </Card>
      <Card className='scada-card'>
        <SnowChart
          options={options}
          plant={props.plant}
          startDate={props.startDate}
          endDate={props.endDate}
        />
      </Card>
    </div>
  )
}
