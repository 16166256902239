import React, { useEffect, useState } from "react"

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'

import { Box, DialogProps } from "@mui/material"
import { useTheme } from '@mui/material/styles'
import useMediaQuery from "@mui/material/useMediaQuery"

import CloseIcon from '@mui/icons-material/Close'

import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TabContext from '@mui/lab/TabContext'

import TransitionSlideUp from "../../../../service/transition/TransitionSlideUp"
import { Roughness } from '../model/Roughness';
import { apiRoughness } from "../../../../service/api/ApiRoughness"
import RoughnessTab from "./RoughnessTab"

type Props = {
  id: number
  open: boolean
  selectedRoughness: Roughness|null
  onClose: () => void
  onSelect: (roughness: Roughness) => void
}

export default function RoughnessDialog(props: Props) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [maxWidth] = useState<DialogProps['maxWidth']>('lg')

  // par defaut mer ou lac => z0 à 0.005
  const [value, setValue] = useState('0.005')
  const [items, setItems] = useState<Roughness[]>([])

  useEffect(() => {
    apiRoughness.getRoughnessTypes(
    ).then(data => {
      setItems(data)
    })
  }, [])

  useEffect(() => {
    if (props.selectedRoughness === null) return

    setValue(props.selectedRoughness.z0.toString())
  }, [props.selectedRoughness, open])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  }

  const onClose: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    props.onClose();
  }

  const onSelect = () => {
    const currentRoughness = items.find(item => item.z0.toString() === value)

    if (currentRoughness === undefined) {
      console.error('Roughness not found in RoughnessDialog')
      return
    }

    props.onSelect(currentRoughness)
    props.onClose()
  }

  return (
    <Dialog maxWidth={maxWidth} fullScreen={isMobile} open={props.open} onClose={onClose} style={{ borderRadius: 22 }} TransitionComponent={TransitionSlideUp}>
      <DialogTitle style={{ padding: 0 }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Identifier le bon type de sol
          </Typography>
          <IconButton
            color="secondary"
            style={{ background: 'rgba(53, 186, 7, 0.12)' }}
            aria-label="close dialog"
            edge="start"
            onClick={props.onClose}
          >
            <CloseIcon style={{ color: (theme.palette.mode === 'dark') ? theme.palette.common.white : theme.palette.common.black }} />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <Divider style={{ margin: 0 }}></Divider>
      <DialogContent>
        <Box width={{ xs: '100%', sm: 950 }}>
          <TabContext value={value}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs tracker"
            >
              {items.map((item) => {
                return <Tab label={item.label} value={item.z0.toString()} />
              })}
            </Tabs>
            {items.map((item) => {
              return <RoughnessTab
                item={item}
              />
            })}
          </TabContext>
        </Box>
      </DialogContent>
      <DialogActions style={{ paddingRight: '24px', paddingBottom: '24px' }}>
        <Button variant="outlined" color="inherit" onClick={props.onClose}>Annuler</Button>
        <Button variant="outlined" onClick={onSelect}>Sélectionner ce sol</Button>
      </DialogActions>
    </Dialog>
  )
}
