import Box from '@mui/material/Box';
import PrivateRoutes from '../../../PrivateRoutes';

export default function ImplantationV2() {
  return (
    <Box sx={{ display: 'flex' }}>
      <PrivateRoutes authField='authAppImplantation'/>
    </Box>
  );
}
