import React, {ComponentProps} from "react";
import {Control, Controller} from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

type ControlledSelectOptionProps = {
    value: number | string;
    label: string;
}

type ControlledSelectProps = {
    name: string;
    label: string;
    required?: boolean;
    control: Control<any>;
    options: ControlledSelectOptionProps[];
    style?: React.CSSProperties | undefined;
} & ComponentProps<typeof Select>

const ControlledSelect = (
{
    id,
    name,
    label,
    control,
    options,
    size,
    variant,
    fullWidth,
    style,
    required = false
}: ControlledSelectProps) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState: {error}}) => (
                <FormControl fullWidth={fullWidth} variant={variant} style={style}>
                    <InputLabel id={`${id}-label`} error={!!error} required={required}>{label}</InputLabel>
                    <Select
                        size={size}
                        labelId={`${id}-label`}
                        label={label}
                        id={id}
                        color='primary'
                        error={!!error}
                        {...field}
                        {...control.register(name)}
                    >
                        {options.map((option, idx) =>
                            (
                                <MenuItem
                                    key={idx}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
            )}
        />
    );
}

export default ControlledSelect;