import apiSizea from '../../../../service/api/ApiSizea';
import { useEffect, useState } from 'react';
import { ConverterType } from "../../../../interfaces/ess/ConverterType";
import { SelectType } from './SelectType';
import { FieldValues, UseControllerProps } from 'react-hook-form';
import { SxProps } from '@mui/material';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
    error: boolean | undefined
    sx: SxProps
}

export function SelectConverterType<T extends FieldValues>(props: Props<T>) {
    const [converterTypes, setConverterTypes] = useState<ConverterType[]>([]);
    useEffect(() => {
        apiSizea.converterTypes().then(data => {
            setConverterTypes(data);
        });
    }, [])

    if (converterTypes.length === 0) {
        return <></>
    }

    return <SelectType
        types={converterTypes}
        sx={props.sx}
        error={props.error}
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue}
    />
}
