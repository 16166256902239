import apiV2 from './api/ApiV2';
import { forkJoin, lastValueFrom } from 'rxjs';
import { PlantObjects } from '../store/scada/plant/PlantObjectsSlice';

export async function GetPlantObjects(plantId: number): Promise<PlantObjects> {
    const plantObjectsObservable = forkJoin({
        plant: apiV2.plant(plantId),
        trackers: apiV2.trackers(plantId),
        immersionHeaters: apiV2.immersionHeaters(plantId),
        ess: apiV2.ess(plantId),
        meter: apiV2.meter(plantId)
    })

    const data = await lastValueFrom(plantObjectsObservable)

    const plantObjects = {
        plant: data.plant,
        trackers: data.trackers.map((x) => { return { isSelected: true, tracker: x } }),
        immersionHeaters: data.immersionHeaters.map((x) => { return { isSelected: true, immersionHeater: x } }),
        ess: data.ess.map((x) => { return { isSelected: true, ess: x } }),
        meter: { isSelected: true, meter: data.meter.length > 0 ? data.meter[0] : null }
    } as PlantObjects

    return plantObjects
}
