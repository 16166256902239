import Box from '@mui/material/Box'
import Form from './Form'

export default function ImplantationViewV2() {
  return (
    <Box sx={{ justifyContent: "center", display: "flex", width: "100%" }}>
      <Form />
    </Box>
  )
}
