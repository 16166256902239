import { Accordion, AccordionDetails, AccordionSummary, Grid, Theme, useTheme } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSlots } from '@mui/material/Accordion';
import Fade from '@mui/material/Fade';
import FormControl from "@mui/material/FormControl";
import Filter from "./Filter";
import FilterMultiple from "./FilterMultiple";
import { useEffect, useState } from "react";
import apiV2 from "../../../../service/api/ApiV2";
import { forkJoin } from 'rxjs';
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
    addNotInPlantStatusTypes,
    removeNotInPlantStatusTypes,
    setEndOperationDate,
    setInAgencies,
    setNotSynchronised,
    setStartOperationDate,
    setSavStatus,
    setPlatform,
    setPlantTags
} from "../../../../store/scada/plants/ScadaFilterValuesSlice";
import CheckBoxFilter from "./CheckBoxFilter";
import { PlantStatusType } from "../../../../interfaces/PlantStatusTypes";
import dayjs, { Dayjs } from "dayjs";
import AppDatepicker from "../../../../component/AppDatepicker";
import PlantStatusFilters from "./PlantStatusFilters";
import { PlatformTypeEnum } from "../../../../interfaces/client/PlatformTypeEnum";
import { SavStatusEnum } from "../../../../interfaces/SavStatusEnum";
import AutocompletePlantTag from "../../../../component/form/AutocompletePlantTag";


type filterOptions = { id: string | number, label: string }[]

type onChangeInput = (number | string) | (number | string)[] | null

export default function Filters() {
    const [ready, setReady] = useState<boolean>(false);
    const [agencyOptions, setAgencyOptions] = useState<filterOptions>();
    const [plantStatusTypeOptions, setPlantStatusTypeOptions] = useState<filterOptions>();
    const [plantStatusTypeColors, setPlantStatusTypeColors] = useState<string[]>();
    const [expanded, setExpanded] = useState(false);
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const scadaFilterValues = useAppSelector((state) => state.scadaFilterValues.value)
    const savStatusOptions = [{id: SavStatusEnum.VIEWED, label: "Toutes Traitées"}, {id: SavStatusEnum.NEW, label: "Non traitées"}]
    const platformOptions = [{id: PlatformTypeEnum.LUMIOO, label: "Lumioo"}, {id: PlatformTypeEnum.OKWIND, label: "Okwind"}]

    const handleExpansion = () => {
      setExpanded((prevExpanded) => !prevExpanded);
    };

    function getPlantStatusTypeColors(plantStatusTypes: PlantStatusType[], theme: Theme): string[] {
        const colors: string[] = []
        plantStatusTypes.forEach(x => {
            if (x.label === 'En alarme critique') colors.push(theme.palette.error.main);
            if (x.label === 'En alarme') colors.push(theme.palette.warning.main);
            if (x.label === 'En fonctionnement') colors.push(theme.palette.success.main);
        })
        return colors
    }

    useEffect(() => {
        const filterData = forkJoin({
            agencies: apiV2.agencies(),
            plantStatusTypes: apiV2.plantStatusTypes(),
        });
        filterData.subscribe({
            next: (results) => {
                setAgencyOptions(results.agencies.map(x => { return { label: x.name, id: x.id } }))
                setPlantStatusTypeOptions(results.plantStatusTypes)
                setPlantStatusTypeColors(getPlantStatusTypeColors(results.plantStatusTypes, theme))
                setReady(true)
            },
        })
    }, [theme])

    const selectAgencies = (value: onChangeInput): void => {
        dispatch(setInAgencies(value))
    }

    const unselectStatusTypes = (value: onChangeInput, isSelected: boolean): void => {
        if (isSelected) {
            dispatch(removeNotInPlantStatusTypes(value))
            return
        }
        dispatch(addNotInPlantStatusTypes(value))
    }

    const unselectNotSynchronised = (isSelected: boolean): void => {
        dispatch(setNotSynchronised(isSelected))
    }

    const selectOperationDates = (value: [Dayjs | null, Dayjs | null]): void => {
        dispatch(setStartOperationDate(value[0] !== null ? value[0].format('YYYY-MM-DD') : null))
        dispatch(setEndOperationDate(value[1] !== null ? value[1].format('YYYY-MM-DD') : null))
    }

    const selectSavStatus = (value: onChangeInput): void => {
        dispatch(setSavStatus(value))
    }

    const selectPlatform = (value: onChangeInput): void =>  {
        dispatch(setPlatform(value))
    }

    return (
        <div>
            {ready &&
                <Accordion
                    expanded={expanded}
                    onChange={handleExpansion}
                    slots={{ transition: Fade as AccordionSlots['transition'] }}
                    slotProps={{ transition: { timeout: 200 } }}
                    sx={{
                    '& .MuiAccordion-region': {  height: expanded ? 'auto' : 0 },
                    '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none', marginBottom: expanded ? '20px' : '0px' },
                    }}
                    style={{borderRadius: '10px', paddingLeft: '10px',  marginBottom: !expanded ? '20px' : '0px'}}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{flexDirection: "row-reverse"}}>
                        <Grid container style={{paddingLeft: '10px'}}>
                            <Grid item xs={12} md={9}>
                                <PlantStatusFilters
                                    options={plantStatusTypeOptions!}
                                    colors={plantStatusTypeColors!}
                                    onChange={unselectStatusTypes}
                                    // return the ids of status that are selected (we invert scadaFilterValues.notInPlantStatusTypes)
                                    value={plantStatusTypeOptions!.filter(x => !scadaFilterValues.notInPlantStatusTypes.includes(x.id as string)).map(y => y.id)}
                                />
                                <CheckBoxFilter
                                    style={{color: theme.palette.notSynchronised}}
                                    label={"Déconnectée"}
                                    onChange={unselectNotSynchronised}
                                    value={scadaFilterValues.notSynchronised}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3} >
                                <Filter
                                    options={savStatusOptions}
                                    label="Traitements des alarmes"
                                    onChange={selectSavStatus}
                                    value={scadaFilterValues.savStatus}
                                    style={{width: 280, height: 20, marginBottom: "20px"}}
                                />
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails sx={{pt: 0, pb: 0}}>
                        <Grid container sx={{ mt: 0, pl: 4 }} columnSpacing={2}>
                            <Grid item xs={12} lg={3}>
                                <FilterMultiple
                                    options={agencyOptions!}
                                    label="Agence"
                                    onChange={selectAgencies}
                                    value={scadaFilterValues.inAgencies}
                                />
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <AppDatepicker
                                    value={[
                                        scadaFilterValues.startOperationDate ? dayjs(scadaFilterValues.startOperationDate, 'YYYY-MM-DD') : null,
                                        scadaFilterValues.endOperationDate ? dayjs(scadaFilterValues.endOperationDate, 'YYYY-MM-DD') : null]}
                                    onChange={selectOperationDates}
                                    allowEmpty={[true, true]}
                                    style={{ height: 39, backgroundColor: "inherit", marginBottom: "20px" }}
                                    placeholder={['Mise en route', 'Mise en route']}
                                />
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <Filter
                                    options={platformOptions}
                                    label="Client"
                                    onChange={selectPlatform}
                                    value={scadaFilterValues.platform}
                                    style={{height: 20, marginBottom: "30px"}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <FormControl fullWidth>
                                    <AutocompletePlantTag
                                        onChange={(newValue)=> {
                                            dispatch(setPlantTags(newValue.map(val => val.label)))
                                        }}
                                        size={'small'}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
        </div>
    );
}