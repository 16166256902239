import { faFileExcel, faFileWord } from '@fortawesome/free-solid-svg-icons';
import DownloadFile from './DownloadFile';
import Tooltip from '@mui/material/Tooltip'
import { Box } from '@mui/material'
import { Help } from '@mui/icons-material';

interface Props {
  status: string
  errorDescription: string | null
  resultExcelFile: string | null
  resultWordFile: string | null
}
export default function SimulationStatus(props: Props) {
  const doneStatus = "DONE";
  const getLabel = function (status: string) {
    if (status === 'CREATED') return 'Créé'
    if (status === 'RUNNING') return 'En cours'
    if (status === 'DONE') return ''
    if (status === "ERROR")
      return (
        <Box sx={{ display: "flex", alignItems: "end" }}>
          <span style={{ marginRight: 5 }}>Echec</span>
          <Help />
        </Box>
      )

    return 'N/A'
  }

  const getStyle = function (status: string) {
    if (status === 'DONE') {
      return { color: 'green' }
    }
    if (status === 'ERROR') {
      return { color: 'red' }
    }

    return {}
  }

  return (
    <span style={getStyle(props.status)}>
      <Tooltip title={props.errorDescription}>
        <Box>
          {getLabel(props.status)}
        </Box>
      </Tooltip>
      {props.status === doneStatus && props.resultExcelFile &&
        <DownloadFile title='Fichier EXCEL' file={props.resultExcelFile} faIcon={faFileExcel} type={'simulation'}/>
      }
      {props.status === doneStatus && props.resultWordFile &&
        <span style={{ marginLeft: '1em' }}>
          <DownloadFile title='Fichier WORD' file={props.resultWordFile} faIcon={faFileWord} type={'simulation'}/>
        </span>
      }
    </span>
  );
}
