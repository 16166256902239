import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { Tracker } from '../../../interfaces/Tracker'
import { Plant } from '../../../interfaces/Plant';
import { ImmersionHeater } from '../../../interfaces/ImmersionHeater';
import { Meter } from '../../../interfaces/Meter';
import { Ess } from "../../../interfaces/ess/Ess";

export interface PlantObjects {
  plant: Plant;
  trackers: { isSelected: boolean, tracker: Tracker }[];
  immersionHeaters: { isSelected: boolean, immersionHeater: ImmersionHeater }[];
  ess: { isSelected: boolean, ess: Ess }[];
  meter?: { isSelected: boolean, meter: Meter };
}

export interface PlantObjectsState {
  value: PlantObjects | null
}

const initialState: PlantObjectsState = {
  value: null
}

export const plantObjectsSlice = createSlice({
  name: 'plantObjects',
  initialState,
  reducers: {
    toggleTracker: (state, action) => {
      if (state.value === null) return;
      const { id, isSelected } = action.payload
        state.value.trackers = state.value?.trackers.map(
          (x) => x.tracker.id === id
            ? { isSelected: isSelected, tracker: x.tracker }
            : x)
    },
    toggleImmersionHeater: (state, action) => {
      if (state.value === null) return;
      const { id, isSelected } = action.payload
        state.value.immersionHeaters = state.value.immersionHeaters.map(
          (x) => x.immersionHeater.id === id
            ? { isSelected: isSelected, immersionHeater: x.immersionHeater }
            : x)
    },
    toggleEss: (state, action) => {
      if (state.value === null) return;
      const { id, isSelected } = action.payload
      state.value.ess = state.value.ess.map(
          (x) => x.ess.id === id
              ? { isSelected: isSelected, ess: x.ess }
              : x)
    },
    toggleMeter: (state, action) => {
      if (state.value === null) return;
      if (state.value.meter) state.value.meter.isSelected = action.payload.isSelected
    },
    toggleAll: (state, action) => {
      if (state.value === null) return;
      state.value.trackers = state.value.trackers.map((x) => {return {tracker: x.tracker, isSelected: action.payload.isSelected}})
      state.value.immersionHeaters = state.value.immersionHeaters.map((x) => {return {immersionHeater: x.immersionHeater, isSelected: action.payload.isSelected}})
      state.value.ess = state.value.ess.map((x) => {return {ess: x.ess, isSelected: action.payload.isSelected}})
      if (state.value.meter) state.value.meter.isSelected = action.payload.isSelected
    },
    setPlantObjects: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { setPlantObjects, toggleTracker, toggleImmersionHeater, toggleEss, toggleMeter, toggleAll } = plantObjectsSlice.actions

export const selectPlantObjects = (state: RootState) => state.plantObjects.value

export default plantObjectsSlice.reducer