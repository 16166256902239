import {createSlice} from "@reduxjs/toolkit";

import {PlantFilters} from "../../interfaces/control/PlantFilters";
import PlantStatusEnum from "../../enums/PlantStatusEnum";

const initialState: PlantFilters = {
    search: '',
    begin: null,
    end: null,
    agency: -1,
    status: PlantStatusEnum.ALL,
};

export const ControlFiltersSlice = createSlice({
    name: 'controlFilters',
    initialState,
    reducers: {
        setSearchFilter: (state, action) => {
            state.search = action.payload;
        },
        setBeginFilter: (state, action) => {
            state.begin = action.payload;
        },
        setEndFilter: (state, action) => {
            state.end = action.payload;
        },
        setAgencyFilter: (state, action) => {
            state.agency = action.payload;
        },
        setStatusFilter: (state, action) => {
            state.status = action.payload;
        },
    }
});


export const {setSearchFilter, setBeginFilter, setEndFilter, setAgencyFilter, setStatusFilter} = ControlFiltersSlice.actions;
export default ControlFiltersSlice.reducer;
