import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { forkJoin } from 'rxjs';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import SettingsIcon from '@mui/icons-material/Settings';
import Chip from '@mui/material/Chip';;
import CardMedia from '@mui/material/CardMedia';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import { TrackerStatus } from '../../../../../interfaces/TrackerStatus';
import SynchronisedDiv from '../SynchronisedDiv';
import StatusDiv from '../StatusDiv';
import apiV2 from '../../../../../service/api/ApiV2';
import { Tracker } from '../../../../../interfaces/Tracker';
import { AlarmsList } from '../AlarmsList';
import AppCollapse from '../AppCollapse';
import image from '../../../../../assets/icon_tracker.svg'

import { toggleTracker } from '../../../../../store/scada/plant/PlantObjectsSlice';
import { useAppDispatch } from '../../../../../store/hooks';
import { RootState } from "../../../../../store/store";

import TrackerDialog from './TrackerDialog';
import ClipBoardButton from '../../../../../service/ClipboardService';

type TrackerCardProps = {
  tracker: Tracker;
  isSelected: boolean;
}

export default function TrackerCard(props: TrackerCardProps) {
  const [trackerStatus, setTrackerStatus] = useState<TrackerStatus>();
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const trackers = useSelector((state: RootState) => state.trackers);
  const index = trackers.data.findIndex((x: Tracker) => x.id === props.tracker.id)
  const dispatch = useAppDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function toggleSelect(event: React.ChangeEvent<HTMLInputElement>){
    dispatch(toggleTracker({id: props.tracker.id, isSelected: event.target.checked}))
  }

  useEffect(() => {
    const status = forkJoin({
      trackerStatus: apiV2.trackerStatus(props.tracker.id),
    });
    status.subscribe({
      next: (results) => {
        setTrackerStatus(results.trackerStatus);
      },
    })
  }, [props.tracker]);

  if (!trackerStatus) return <></>;
  return (
    <Card className='scada-card' >
      <Grid container style={{paddingTop:'15px', paddingLeft:'10px'}}>
        <Grid item xs={2}>
          <CardMedia
            style={{backgroundColor: (theme.palette.mode === 'dark') ? '#757575' : 'transparent', borderRadius: '50%', padding: '2px', marginLeft: '15px'}}
            component="img"
            image={image}
            className='scada-card-image'
            />
            {
              (trackers.data[index].isMaster) && 
              <Chip style={{marginLeft: '5px'}} size="small" label="Maître" />
            }
        </Grid>
        <Grid item xs={8} style={{ paddingLeft: '10px'}}>
          <Typography component="div" className='scada-card-title'>
            Tracker {trackers.data[index].nTrk} &nbsp;
            <span style={{ color: (theme.palette.mode === 'dark') ? theme.palette.common.white : theme.palette.secondaryFont }}>
              {trackers.data[index].serialNumber}
            </span>
            <span style={{marginLeft: '5px'}}>
              <ClipBoardButton text={trackers.data[index].serialNumber}></ClipBoardButton>
            </span>
          </Typography>
          <SynchronisedDiv isSynchronised={trackerStatus.isSynchronised} />
          {trackerStatus.isSynchronised && <StatusDiv statusType={trackerStatus.statusType} />}
        </Grid>
        <Grid item xs={2} style={{marginTop: '-5px', paddingRight: '10px', textAlign: 'right'}}>
          <Checkbox
            onChange={toggleSelect}
            checked={props.isSelected}
          />
          <Tooltip title="Paramètres">
            <IconButton
              aria-label="delete" onClick={handleOpen} data-cy="cypressTrackerCardSettingsButton"
            >
              <SettingsIcon fontSize="inherit" color="secondary"/>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12} style={{paddingLeft:'10px', paddingRight:'18px', paddingBottom: '10px'}}>
          <AppCollapse title="Alarmes">
            {
              (trackerStatus.alarms.length > 0) ?
                <AlarmsList alarms={trackerStatus.alarms} />
                :
                <p>Aucune alarmes</p>
            }
          </AppCollapse>
        </Grid>
      </Grid>


      <TrackerDialog
        id={1}
        index={index}
        open={open}
        handleClose={handleClose}
      >
      </TrackerDialog>
    </Card>
  );
}