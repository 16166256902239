import { Divider } from "@mui/material";

import CardRow from "../CardRow";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import MapAddress from "../../../../../component/form/MapAddress";
import AppCollapse from "../AppCollapse";

interface propsInterface {
    index: number;
}

export function TrackerSetting(props: propsInterface) {
    const trackers = useSelector((state: RootState) => state.trackers);

    return (
        <div>
            <h4>Configuration logiciel</h4>

            <CardRow label="Offset inclinaison">
                {trackers.data[props.index].offsetElevation}
            </CardRow>
            <CardRow label="Offset azimuth">
                {trackers.data[props.index].offsetHeading}
            </CardRow>
            <CardRow label="Maitre">
                {trackers.data[props.index].isMaster ? "Oui" : (trackers.data[props.index].isMaster === false ? "Non" : "")}
            </CardRow>

            <div style={{marginTop: '20px'}}>
                <Divider></Divider>
            </div>

            <h4>Coordonnées GPS</h4>

            <CardRow label="Latitude">
                {trackers.data[props.index].latitude}
            </CardRow>
            <CardRow label="Longitude">
                {trackers.data[props.index].longitude}
            </CardRow>

            <div style={{marginTop: '20px'}}>
                <Divider></Divider>
                <AppCollapse title="Afficher sur la carte">
                    <MapAddress
                        latitude={Number(trackers.data[props.index].latitude)}
                        longitude={Number(trackers.data[props.index].longitude)}
                        onChange={() => {}}
                        precision={2}
                        mapWidth='99%'
                        mapHeight='330px'
                    />
                </AppCollapse>
            </div>

        </div>
    )
}

