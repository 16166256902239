import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';

import { useTheme } from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";

import { Grid } from '@mui/material'
import { Card } from '@mui/material'
import { Button } from '@mui/material'
import { IconButton } from '@mui/material'
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


import SearchTextField from '../../../component/form/SearchTextField';
import CustomNoRowsOverlay from '../../../component/CustomNoRowsOverlay';

import EditIcon from '@mui/icons-material/Edit';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import FormControl from "@mui/material/FormControl";

import {formatDateTime} from "../../../service/FormatDateTime";
import ToastService from '../../../service/ToastService';
import ConfirmDialogService from '../../../service/ConfirmDialogService';

import DialogPlant from './DialogPlant';

import { PlantSearch } from '../../../interfaces/PlantSearch';

import apiV2 from '../../../service/api/ApiV2';

import { RootState } from "../../../store/store";
import { getClientAppUrl } from '../../../service/ClientAppService';
import AutocompletePlantTag from "../../../component/form/AutocompletePlantTag";
import TagList from "../../../component/TagList";

export default function ListsPlantSearchView() {
    const [searchParams] = useSearchParams();
    const authorization = useSelector((state: RootState) => state.authorization.data);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))

    interface Column {
        id: 'plant' | 'clients' | 'operationDate' | 'actions' | 'tags';
        label: string;
        description: string,
        minWidth?: number;
        align?: 'center';
        format?: (value: number) => string;
    }
    const plantParams = searchParams.get('plant_name');

    const [rows, setRow] = useState<Array<PlantSearch>>([]);
    const [searchPlant, setSearchPlant] = useState<string>(plantParams !== null ? plantParams : '');
    const [plantSearchUpdated, setPlantSearchUpdated] = useState<PlantSearch | null>();
    const [titleDialog, setTitleDialog] = useState<string>("");
    const [actionLabelDialog, setActionLabelDialog] = useState<string>("Créer");
    const [contentTextDialog1, setContentTextDialog1] = useState<string>("");
    const [contentTextDialog2, setContentTextDialog2] = useState<string>("");
    const [open, setOpen] = useState(false);
    const [tags, setTags] = useState<string[]>([]);

    const onChange = (searchedVal: string, tags: string[] = []) => {
        apiV2.searchPlants(searchedVal, tags).then(data => {
            if(data.length > 0){
                setRow(data);
            }else{
                ToastService.warning("Aucune installation trouvée pour votre recherche");
            }
        })
        setSearchPlant(searchedVal)
    };

    const handleAddNewPlant = () => {
        setTitleDialog('Créer une installation')
        setContentTextDialog1('Déclarer une installation d\'un client existant afin d\'en définir les caractéristiques techniques et les équipements.')
        setContentTextDialog2('Définissez les différents équipements de cette installation pour qu\'ils puissent être correctement répertoriés.')
        setActionLabelDialog('Créer')
        setOpen(true);
      };

    const handleUpdatePlant = (plant: PlantSearch) => {
        setTitleDialog('Modifier l\'installation ')
        setContentTextDialog1('Modifier l\'installation ' + plant.name + ' afin d\'en définir les caractéristiques techniques et les équipements.')
        setContentTextDialog2('Modifier les différents équipements de l\'installation ' + plant.name + ' pour qu\'ils puissent être correctement répertoriés.')
        setActionLabelDialog('Modifier')
        setOpen(true);
        setPlantSearchUpdated(plant);
    };

    const handleRemovePlant = (plant: PlantSearch) => {
        ConfirmDialogService.warning(
            'md',
            'Supprimer l\'installation',
            'Etes-vous sur de vouloir supprimer l\'installation <strong>' + plant.name + '</strong> ?',
            '<strong>Cette action est irréversible.</strong>',
            () => {
              apiV2.deletePlant(plant.id).then(response => {
                  if (response.ok) {
                      ToastService.success('L\'installation ' + plant.name + ' a été supprimée')
                      setRow(rows.filter((row) => row.id !== plant.id));
                  } else {
                      ToastService.error('Erreur durant la suppression de l\'installation');
                  }
              })
            }
        );
    };

    const handleUpdateRowPlant = (plant: PlantSearch) => {
        setRow(rows.map((row) => {
            if (row.id === plant.id) return plant
            return row;
        }));
    };

    const handleCloseDialogPlant = () => {
        setOpen(false);
        setTimeout(() => {
            setPlantSearchUpdated(null);
        }, 200);
    };

    const getClientApp = (plant: PlantSearch): string => {
        const isLumioo = plant.platform === 'lumioo';
        return getClientAppUrl(isLumioo, plant.id);
    }

    const getScadaAppUrl = (plant: PlantSearch): string => {
        const today = new Date().toISOString().split('T')[0]
        return '/scada/plant?start_date=' + today + '&end_date=' + today + '&plant_id=' + plant.id;
    }

    const columns: Column[] = [
        {
            id: 'plant',
            label: 'Installations',
            description: 'Information sur l\'installation',
        },
        {
            id: 'clients',
            label: 'Clients',
            description: 'Liste des clients associés à l\'installation',
        },
        {
            id: 'operationDate',
            label: 'Date d\'activation',
            description: 'Date de mise en route de l\'installation',
        },
        {
            id: 'tags',
            label: 'Tags',
            description: 'Tags associés à l\'installation',
        },
        {
            id: 'actions',
            label: 'Actions',
            description: 'Action disponible'
        }
    ];

    useEffect(() => {
        onChange(searchPlant, tags);
    }, [searchPlant, tags]);

    return (
        <Grid container sx={{ backgroundColor: 'transparent',}}>
            <Card className='search-card'>
                <Grid container justifyContent="space-between" sx={{ backgroundColor: 'transparent'}}>
                    <Grid item xs={12} sm={12} md={6} lg={9}>
                        <Grid container columnGap={2}>
                            <Grid item>
                                <SearchTextField
                                    value={searchPlant}
                                    notifyOnClear={false}
                                    width={'285px'}
                                    placeholder="Par nom, adresse, clients..."
                                    onChange={(newValue) => setSearchPlant(newValue)}
                                    helper="La recherche doit contenir au moins 3 caractères."
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <FormControl style={{ marginTop: 15}} fullWidth>
                                    <AutocompletePlantTag
                                        onChange={(newValue)=> setTags(newValue.map((tag) => tag.value))}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} style={{ paddingTop: (isMobile) ? 0 : 30, paddingBottom: (isMobile) ? 20 : 0}} >
                        <Button id="createPlantButtonDialog" sx={{width: '100%'}} variant="outlined" onClick={handleAddNewPlant} startIcon={<SolarPowerIcon />}>Créer une installation</Button>
                    </Grid>
                </Grid>
            </Card>

            <Card className='custom-card'>
                <Grid container sx={{ backgroundColor: 'transparent',  pl:0 , pr: 0 }}>
                <Grid item xs={12} style={{ padding: '10px' }}>
                    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
                    {rows.length === 0 &&
                        <CustomNoRowsOverlay noRowText="Effectuer une recherche pour trouver une installation" loading={null}/>
                    }
                    {rows.length !== 0 &&
                        <TableContainer sx={{ boxShadow: 'none' }}>
                        <Table stickyHeader aria-label="sticky table">
                            {rows.length >= 30 &&
                            <caption>Seuls les 30 premiers résultats sont affichés, veuillez préciser votre recherche.</caption>
                            }
                            <TableHead>
                            <TableRow>
                                {columns.map((column, i) => (
                                <TableCell
                                    key={i}
                                    align='center'
                                    style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                                >
                                    {column.label}
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((plant) => (
                                <TableRow
                                key={plant.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={{padding:0}} align="center">
                                        <List>
                                            <ListItem>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{ display: 'inline' }}
                                                                component="span"
                                                                color="text.primary"
                                                            >
                                                                {plant.name}
                                                            </Typography>
                                                            <Typography
                                                                sx={{ display: 'inline' }}
                                                                component="span"
                                                                variant="body2"
                                                            >
                                                                <br></br>
                                                                {plant.serialNumber}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                    secondary={plant.address}
                                                />
                                            </ListItem>
                                        </List>
                                    </TableCell>
                                    <TableCell style={{padding: 0, textAlign:'center'}} >
                                    {(plant.clients.length > 0)
                                        ?
                                        <List>
                                            <ListItem >
                                                <ListItemText
                                                    style={{textAlign:'center'}}
                                                    primary={
                                                        <React.Fragment>
                                                            <Typography
                                                                component="span"
                                                                color="text.primary"
                                                            >
                                                                {plant.clients[0].firstName + ' ' + plant.clients[0].lastName.toUpperCase() + ' '}
                                                            </Typography>
                                                            { (plant.clients[0].aliasCompany) &&
                                                                <Typography
                                                                    sx={{ display: 'inline' }}
                                                                    component="span"
                                                                    variant="body2"
                                                                    color={theme.palette.primary.main}
                                                                >

                                                                    ({plant.clients[0].aliasCompany})
                                                                </Typography>
                                                            }
                                                            { (plant.clients[0].email) &&
                                                                <Typography
                                                                    sx={{ display: 'inline' }}
                                                                    component="span"
                                                                    variant="body2"
                                                                >
                                                                    <br></br>
                                                                    {plant.clients[0].email}
                                                                </Typography>
                                                            }
                                                            { (plant.clients[0].phone) &&
                                                                <Typography
                                                                    sx={{ display: 'inline' }}
                                                                    component="span"
                                                                    variant="body2"
                                                                >
                                                                    <br></br>
                                                                    {plant.clients[0].phone}
                                                                </Typography>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                    secondary={
                                                        (plant.clients.length > 1) ?
                                                            <React.Fragment>
                                                                <Tooltip
                                                                    title={
                                                                        <List>
                                                                            <ListItem style={{backgroundColor: 'transparent', color: 'white', borderBottom: '1px solid white'}}>
                                                                                <ListItemText>
                                                                                    Utilisateurs secondaires
                                                                                </ListItemText>
                                                                            </ListItem>
                                                                            {plant.clients.slice(1).map(client => (
                                                                                <ListItem key={client.id}>
                                                                                    <ListItemText
                                                                                        primary={client.firstName + ' ' + client.lastName}
                                                                                    />
                                                                                </ListItem>
                                                                            ))}
                                                                        </List>
                                                                    }                                                                                                                    >
                                                                    <span>
                                                                        {'+ ' + (plant.clients.length - 1) + ' utilisateurs secondaires' }
                                                                    </span>
                                                                </Tooltip>
                                                            </React.Fragment>
                                                        :
                                                        ''
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                        :
                                        <span>Aucun client n'est relié à cette installation</span>
                                    }
                                    </TableCell>
                                    <TableCell style={{padding:0}} align="center">
                                        {
                                            (plant.operationDate) ?
                                                formatDateTime(plant.operationDate)
                                            :
                                                <span>Installation non mise en route</span>
                                        }
                                    </TableCell>
                                    <TableCell style={{padding: 0}} align={'center'}>
                                        {plant.tags && <TagList tags={plant.tags.map((tagLabel) => ({label: tagLabel}) )} />}
                                    </TableCell>
                                    <TableCell align="center" style={{width: '250px'}}>
                                        <Box>
                                            {
                                                (authorization?.authAppScada)  &&
                                                <Tooltip title="Application scada">
                                                    <IconButton
                                                        id="openScadaApp"
                                                        color="primary"
                                                        aria-label="open update plant"
                                                        edge="start"
                                                        sx={{
                                                            marginLeft: '15px',
                                                        }}
                                                        href={getScadaAppUrl(plant)}
                                                    >
                                                        <QueryStatsIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            <Tooltip title="Application client">
                                                <IconButton
                                                    id="openPlantApp"
                                                    aria-label="open update plant"
                                                    edge="start"
                                                    sx={{
                                                        marginLeft: '15px',
                                                    }}
                                                    target="_blank"
                                                    href={getClientApp(plant)}
                                                >
                                                    <PhonelinkIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Modifier l'installation">
                                                <IconButton
                                                    id="updatePlantButtonDialog"
                                                    color="secondary"
                                                    aria-label="open update plant"
                                                    edge="start"
                                                    onClick={() => handleUpdatePlant(plant)}
                                                    sx={{
                                                        marginLeft: '15px',
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={(plant.operationDate === null) ? "Supprimer l'installation" : "Installation déjà mis en route, contactez un administrateur"}>
                                                <span>
                                                    <IconButton
                                                        id="deletePlantButtonDialog"
                                                        disabled={!(plant.operationDate === null)}
                                                        color="error"
                                                        aria-label="open remove plant"
                                                        edge="start"
                                                        onClick={() => handleRemovePlant(plant)}
                                                        sx={{
                                                            marginLeft: '10px',
                                                        }}
                                                    >
                                                        <DeleteOutlineIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    }
                    </Paper>
                </Grid>
                </Grid>
            </Card>
            <DialogPlant
                title={titleDialog}
                contentTextStep1={contentTextDialog1}
                contentTextStep2={contentTextDialog2}
                plantSearchUpdated={(plantSearchUpdated !== undefined) ? plantSearchUpdated : null}
                actionLabel={actionLabelDialog}
                open={open}
                handleUpdateRowPlant={handleUpdateRowPlant}
                handleClose={handleCloseDialogPlant}
            />
        </Grid>
    )
}
