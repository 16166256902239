import {Divider} from '@mui/material';
import CardRow from "../CardRow";
import {formatDateTime} from "../../../../../service/FormatDateTime";
import {ImmersionHeater} from "../../../../../interfaces/ImmersionHeater";

interface propsInterface {
    immersionHeater: ImmersionHeater;
}

export default function ImmersionHeaterGeneral(props: propsInterface) {

    return (
        <div>
            <h4>Général</h4>

            <CardRow label="Identifiant">
                {props.immersionHeater.id}
            </CardRow>

            <CardRow label="Référence">
                <i>Bientôt disponible</i>
            </CardRow>

            <CardRow label="Mise en route" >
                {formatDateTime(props.immersionHeater.operationDate)}
            </CardRow>

            <div style={{marginTop: '20px'}}>
                <Divider></Divider>
            </div>

            <h4>Logiciel</h4>

            <CardRow label="Firmware version">
                <i>Bientôt disponible</i>
            </CardRow>

        </div>
    );
}