import { useEffect, useState } from "react"
import { Box, Typography, useTheme } from "@mui/material"
import { RoughnessItem } from "./RoughnessItem"
import { Roughness } from "../model/Roughness"
import { apiRoughness } from "../../../../service/api/ApiRoughness"

interface Props {
  selectedRoughness: Roughness|null
  onClick: CallableFunction
  onOpenDialog: CallableFunction
}

export function RoughnessList(props: Props) {
  const theme = useTheme()

  const [items, setItems] = useState<Roughness[]>([]);
  useEffect(() => {
    apiRoughness.getRoughnessTypes(
    ).then(data => {
      setItems(data)
    })
  }, [])

  const roughnessSelected = (roughness: Roughness) => {
    props.onClick(roughness)
  }

  return <Box
    sx={{
      textAlign: 'center',
    }}
  >
    {items.length === 0 &&
      <Typography sx={{
        color: theme.palette.error.main
      }}>
        Cette zone géographique n’est pas disponible pour le moment
      </Typography>
    }
    {items.map((item) => {
      return <RoughnessItem
        key={`roughness-${item.z0}`}
        item={item}
        onClick={roughnessSelected}
        onOpenDialog={props.onOpenDialog}
        isSelected={props.selectedRoughness !== null && item.z0 === props.selectedRoughness.z0}
      />
    })}
  </Box>
}
