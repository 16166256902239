import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'
import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, SxProps, Theme } from "@mui/material"
import { PoleTypeEnum } from "../model"

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  error: boolean | undefined
  sx?: SxProps<Theme>
}

export function MatType<T extends FieldValues>(props: Props<T>) {
  return <FormControl error={props.error}>
    <Controller
      name={props.name}
      control={props.control}
      rules={{ required: true }}
      render={({ field }) => (
        <RadioGroup
          {...field}
          row
          defaultValue={null}
          aria-labelledby="ClientPlatformRadioGroup"
        >
          <FormControlLabel value={PoleTypeEnum.STANDARD} control={<Radio />} label={'Mât Sandard'} />
          <FormControlLabel value={PoleTypeEnum.SHORTENED} control={<Radio />} label={'Mât Raccourci'} />
        </RadioGroup>
      )} />
    {props.error && (
      <FormHelperText>Vous devez sélectionner un ajustement</FormHelperText>
    )}
  </FormControl>
}
