import { useState } from "react"
import { Box, Typography, useTheme } from "@mui/material"
import { TrackerItem } from "./TrackerItem"
import trackerOkwind from '../../../../assets/tracker/okwind.png'
import trackerLumioo from '../../../../assets/tracker/lumioo.png'
import trackerTrk75 from '../../../../assets/tracker/trk75.png'
import { TrackerType } from "../model/TrackerType"
import { TrackerTypeEnum } from "../model"

interface Props {
  onClick: CallableFunction
}

export function TrackerList(props: Props) {
  const [selected, setSelected] = useState<string | null>(null);
  const theme = useTheme()

  const items: TrackerType[] = [
    {
      value: TrackerTypeEnum.TREA40000,
      label: 'TREA 40000',
      imageUrl: trackerOkwind,
    },
    {
      value: TrackerTypeEnum.TRK75,
      label: 'TRK 75',
      imageUrl: trackerTrk75,
    },
    {
      value: TrackerTypeEnum.LUMIOO,
      label: 'Lumioo',
      imageUrl: trackerLumioo,
    },
  ]

  const itemSelected = (item: TrackerType) => {
    setSelected(item.value)
    props.onClick(item)
  }

  return <Box>
    {items.length === 0 &&
      <Typography sx={{
        color: theme.palette.error.main
      }}>
        Cette zone géographique n’est pas disponible pour le moment
      </Typography>
    }
    {items.map((item) => {
      return <TrackerItem
        key={`trackertype-${item.value}`}
        item={item}
        onClick={itemSelected}
        isSelected={item.value === selected}
      />
    })}
  </Box>
}
