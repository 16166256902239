import { Box, Button, Stack } from "@mui/material"
import { Roughness } from "../model/Roughness"

interface Props {
  item: Roughness
  onClick: CallableFunction
  onOpenDialog: CallableFunction
  isSelected: boolean
}

export function RoughnessItem(props: Props) {
  return <Button
    sx={{
      width: { xs: '100%', sm: 197 },
      borderRadius: 5,
      border: 1,
      padding: 1,
      margin: 1,
      backgroundColor: props.isSelected ? '#e8f6dd' : 'inherit',
      ":hover": {
        backgroundColor: props.isSelected ? '#e8f6dd' : 'inherit',
      }
    }}
    onClick={() => props.onClick(props.item)}
  >
    <Stack
      direction={'column'}
      alignItems={'center'}
      height={{ sm: 260 }}
    >
      <Box
        sx={{
          color: props.item.color,
          fontWeight: 'bold',
        }}
      >
        {props.item.label}
      </Box>
      <Box
        sx={{
          display: { xs: props.isSelected ? 'block' : 'none', sm: 'block' },
        }}
      >
        <Box
          sx={{
            maxHeight: 150,
            maxWidth: 150,
            position: 'relative',
          }}
        >
          {props.item.images.length > 1 &&
            <span
              style={{
                borderRadius: '50%',
                border: '1px solid',
                width: 25,
                display: 'block',
                position: 'absolute',
                right: 6,
                top: 6,
                color: "white",
              }}
              onClick={()=> props.onOpenDialog()}
            >
              {props.item.images.length}
            </span>
          }
          <img alt='tracker'
            style={{
              width: '100%',
              height: '100%',
            }}
            src={props.item.images[0]}
          />
        </Box>
      </Box>
      <Box
        sx={{
          minHeight: { xs: 0, sm: 65 },
          overflow: 'hidden',
          color: '#a7a7a7',
          textTransform: 'none',
          textAlign: 'left',
        }}
      >
        {props.item.description}
      </Box>
    </Stack>
  </Button>
}
