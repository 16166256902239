import { configureStore } from '@reduxjs/toolkit'
import plantObjectsReducer from './scada/plant/PlantObjectsSlice'
import scadaFilterValuesReducer from './scada/plants/ScadaFilterValuesSlice'
import alarmSelectedReducer from './scada/plants/AlarmSelectedSlice'
import controlFilterReducer from './control/ControlFiltersSlice';

import themeReducer from './theme/theme.store'

import agenciesReducer from './form/agencies.store'
import installatorsReducer from './form/installators.store'
import trackerTypesReducer from './form/trackerTypes.store'

import trackersReducer from './scada/tracker/trackers.store'

import authorizationReducer from './auth/authorization.store'

import checkpointsBlockReducer from './control/checkpointsBlock.store'

import pvPanelTypesReducer from './form/pvPanelTypes.store'

import controlPlantsReducer from './control/plants.store'

import alarmTypeReducer from './scada/alarms/AlarmTypeStore'

import plantTagsReducer from './form/plantTags.store'

export const store = configureStore({
  reducer: {
    plantObjects: plantObjectsReducer,
    scadaFilterValues: scadaFilterValuesReducer,
    alarmSelected: alarmSelectedReducer,
    agencies: agenciesReducer,
    installators: installatorsReducer,
    trackerTypes: trackerTypesReducer,
    authorization: authorizationReducer,
    checkpointsBlock: checkpointsBlockReducer,
    theme: themeReducer,
    trackers: trackersReducer,
    pvPanelTypes: pvPanelTypesReducer,
    plantsToControl: controlPlantsReducer,
    controlFilters: controlFilterReducer,
    alarmTypes: alarmTypeReducer,
    plantTags: plantTagsReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
  }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch