import PlantCard from './plant/PlantCard';
import { TrackerCards } from './TrackerCards';
import { ImmersionHeaterCards } from './immersionHeater/ImmersionHeaterCards';
import MeterCard from './meter/MeterCard';
import MigrationCard from './MigrationCard';
import CardPlantAlarmsNotViewedBySav from './CardPlantAlarmsNotViewedBySav';
import dayjs from 'dayjs';
import { useAppSelector } from '../../../../store/hooks';
import SelectAllPlantObjects from './SelectAllPlantObjectsCard';
import {EssCards} from "./ess/EssCards";
import { useSearchParams } from 'react-router-dom';
import { checkDates } from '../DateService';


export default function CardArea() {
  const plantObjects = useAppSelector((state) => state.plantObjects.value)
  const [searchParams] = useSearchParams();

  // wait for end_date and start_date to be in url
  if (!searchParams.get('start_date') || !searchParams.get('end_date')) {
    return <></>
  }
  // no need for utc, no need to add 1 day (like for chartArea) because we don't get timeseries data
  const startDate = dayjs(searchParams.get('start_date'), 'YYYY-MM-DD');
  const endDate = dayjs(searchParams.get('end_date'), 'YYYY-MM-DD');
  if (!checkDates(startDate, endDate)){
    return <></>;
  }

  if (plantObjects === null) { // data have not been loaded yet
    return <></>
  }
  return (
    <div id="plantObjectCards">
      <PlantCard plant={plantObjects.plant} />
      <SelectAllPlantObjects plantObjects={plantObjects} />
      <TrackerCards trackers={plantObjects.trackers} />
      <ImmersionHeaterCards immersionHeaters={plantObjects.immersionHeaters} />
      <EssCards ess={plantObjects.ess} />
      {plantObjects.meter?.meter && <MeterCard meter={plantObjects.meter.meter} isSelected={plantObjects.meter.isSelected} />}
      <CardPlantAlarmsNotViewedBySav plantId={plantObjects.plant.id}/>
      <MigrationCard plant={plantObjects.plant} startDate={startDate} endDate={endDate}/>
    </div>
  );
}