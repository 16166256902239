import { createTheme, Theme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';

declare module '@mui/material/styles' {
    interface Theme {
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
    }
    interface Palette {
        noData: string;
        scadaBackground: string;
        cardGrey: string;
        toolbarBackground: string;
        inverterProductionCurves: string[];
        immersionHeaterConsumptionCurves: string[];
        trackerWindCurves: string[];
        trackerElevationCurves: string[];
        trackerExpectedElevationCurve: string;
        trackerAzimuthCurves: string[];
        trackerExpectedAzimuthCurve: string;
        eventChartLabel: string;
        plantProductionCurve: string;
        plantConsumptionCurve: string;
        plantWindCurve: string;
        windThresoldCurve: string;
        snowCurve: string;
        trackerAlarmCurve: string;
        immersionHeaterAlarmCurve: string;
        soutirageEnedisCurve: string;
        notSynchronised: string;
        secondaryFont: string;
        neutralColor: string;
        checkpointResultFailed: string;
        checkpointResultInProgress: string;
        checkpointResultOk: string;
        checkpointResultUncertain: string;
        checkpointResultKo: string;
        toControl: string;
        compliant: string;
        notCompliant: string;
        controlInProgress: string;
    }
    interface PaletteOptions {
        noData: string;
        scadaBackground: string;
        toolbarBackground: string;
        inverterProductionCurves: string[];
        immersionHeaterConsumptionCurves: string[];
        trackerWindCurves: string[];
        trackerElevationCurves: string[];
        trackerExpectedElevationCurve: string;
        trackerAzimuthCurves: string[];
        trackerExpectedAzimuthCurve: string;
        eventChartLabel: string;
        plantProductionCurve: string;
        plantConsumptionCurve: string;
        plantWindCurve: string;
        windThresoldCurve: string;
        snowCurve: string;
        trackerAlarmCurve: string;
        immersionHeaterAlarmCurve: string;
        soutirageEnedisCurve: string;
        notSynchronised: string;
        secondaryFont: string;
        neutralColor: string;
    }
}

const baseTheme = (mode: string) => {
    return {
        palette: {
            mode: mode as PaletteMode,
            primary: {
                main: '#35ba07',
                light: '#808eba'
            },
            secondary: {
                main: '#044589',
                light: '#7ecbb6'
            },
            success: {
                main: '#35ba07'
            },
            warning: {
                main: '#F7B32B'
            },
            error: {
                main: '#D72638'
            },
            noData: '#8e8e8e',
            toolbarBackground: '#eaeaea',
            scadaBackground: '#ffffff',
            cardGrey: '#F5F5F5',
            inverterProductionCurves: ['#A6A817', '#80A500', '#5D9A34', '#2E6F3D', '#83f8cd', '#3BBA8A', '#005A4A', '#9BCA4D', '#FFC300', '#D18501', '#B04B08', '#8FBC8F', '#4682B4', '#5F9EA0', '#6A5ACD', '#492EF5', '#B0E0E6', '#9A9A9A', '#C0C0C0', '#F5E5B8', '#E1C78D', '#FDDA8C'],
            immersionHeaterConsumptionCurves: ['#ece7f2', '#d0d1e6', '#a6bddb', '#3690c0', '#74a9cf', '#0570b0', '#045a8d'],
            trackerWindCurves: ['#54424B', '#7A4E69', '#8A496F', '#995A82', '#A06C94', '#915F89', '#A06D9B', '#A477B0', '#9675A1', '#9B7FA9', '#A98FC4', '#9C8BB7', '#A8A2C6', '#A2A1C9', '#B6A5D1', '#B1B3E0', '#C6C8E9', '#C9D3F1', '#B8B7D3', '#ACB7DA', '#A2B4D6'],
            trackerElevationCurves: ['#A6A817', '#80A500', '#5D9A34', '#2E6F3D', '#83f8cd', '#3BBA8A', '#005A4A', '#9BCA4D', '#FFC300', '#D18501', '#B04B08', '#8FBC8F', '#4682B4', '#5F9EA0', '#6A5ACD', '#492EF5', '#B0E0E6', '#9A9A9A', '#C0C0C0', '#F5E5B8', '#E1C78D', '#FDDA8C'],
            trackerExpectedElevationCurve: '#89A6FB',
            trackerAzimuthCurves: ['#A6A817', '#80A500', '#5D9A34', '#2E6F3D', '#83f8cd', '#3BBA8A', '#005A4A', '#9BCA4D', '#FFC300', '#D18501', '#B04B08', '#8FBC8F', '#4682B4', '#5F9EA0', '#6A5ACD', '#492EF5', '#B0E0E6', '#9A9A9A', '#C0C0C0', '#F5E5B8', '#E1C78D', '#FDDA8C'],
            trackerExpectedAzimuthCurve: '#89A6FB',
            eventChartLabel: '#000000',
            plantProductionCurve: '#ffd500',
            plantConsumptionCurve: '#05468c',
            plantWindCurve: '#BCD2EE',
            windThresoldCurve: '#D72638',
            snowCurve: '#DBDFAC',
            trackerAlarmCurve: '#BFAB25',
            immersionHeaterAlarmCurve: '#BFAB25',
            soutirageEnedisCurve: '#009870',
            notSynchronised: '#236AB6',
            secondaryFont: 'rgb(0, 0, 0, 0.4)',
            neutralColor: '#808080',
            checkpointResultFailed: 'red',
            checkpointResultInProgress: 'gray',
            checkpointResultOk: 'green',
            checkpointResultUncertain: '#3778bd',
            checkpointResultKo: 'orange',
            toControl: 'grey',
            compliant: 'green',
            notCompliant: 'orange',
            controlInProgress: '#3778bd',
        },
        typography: {
            fontFamily: 'Montserrat'
        },
    }
}

const overrides = {
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: () =>
                    getTheme('light').unstable_sx({
                        backgroundColor: '#044589',
                    },
                    )
            }
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: () =>
                    getTheme('light').unstable_sx({
                        backgroundColor: '#35ba07',
                        color: 'white',
                        width: '100%',
                        bottom: 0,
                        position: 'fixed',
                        alignItems: 'center',
                        display: 'flex'
                    },
                    )
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginBottom: '20px!important'
                },
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: 'None'
                },
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: 0
                },
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    paddingTop: '4px!important',
                    paddingBottom: '4px!important'
                },
            }
        }
    }
}

const getTheme = (mode: string): Theme => {
    return createTheme({
        ...baseTheme(mode),
        ...overrides
    });
}

export default getTheme;