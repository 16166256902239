import { Grid } from "@mui/material";
import MapOfPlantStatuses from "./MapOfPlantStatuses";
import { useEffect, useState } from "react";
import { MapOfPlantStatusesItem } from "../../../../interfaces/MapOfPlantStatusesItem";
import PieChartOfPlantStatus from "./PieChartOfPlantStatus";
import { forkJoin } from 'rxjs';
import apiV2 from "../../../../service/api/ApiV2";
import { useAppSelector } from "../../../../store/hooks";
import filterArrayByPlants from "../ScadaFilterService";
import dayjs from "dayjs";
import { CurrentAlarmItem } from "../../../../interfaces/CurrentAlarmItem";
import AlarmsTable from "./AlarmsTable";
import CountAlarmByLevelCharts from "./CountAlarmByLevelCharts";

const autoRefreshInterval = 300 // in seconds

export default function ChartArea() {
  const [mapOfPlantStatuses, setMapOfPlantStatuses] = useState<Array<MapOfPlantStatusesItem>>();
  const [currentAlarms, setCurrentAlarms] = useState<Array<CurrentAlarmItem>>();
  const scadaFilterValues = useAppSelector((state) => state.scadaFilterValues.value)

  useEffect(() => {
    const fetchData = () => {
      // add one day to operation date to get the plants that have been operated during the day of scadaFilterValues.endOperationDate
      const endOperationDate = scadaFilterValues.endOperationDate ? dayjs(scadaFilterValues.endOperationDate, 'YYYY-MM-DD') : null
      const endOperationDateOneDayAfter = endOperationDate ? endOperationDate.add(1, 'd').format('YYYY-MM-DD') : null
      forkJoin({
        mapOfPlantStatuses: apiV2.mapOfPlantStatuses(
          scadaFilterValues.inAgencies,
          scadaFilterValues.notInPlantStatusTypes,
          scadaFilterValues.notSynchronised,
          scadaFilterValues.startOperationDate,
          endOperationDateOneDayAfter,
          scadaFilterValues.savStatus,
          scadaFilterValues.platform,
          scadaFilterValues.plantTags
          ),
          currentAlarms: apiV2.currentAlarms(),
      }).subscribe({
        next: (results) => {
          setMapOfPlantStatuses(results.mapOfPlantStatuses)
          const plants = results.mapOfPlantStatuses.map(x => x.plantId)
          setCurrentAlarms(filterArrayByPlants(results.currentAlarms, plants) as CurrentAlarmItem[])
        },
      })
    }
    fetchData() // init data
    // data are fetched every autoRefreshInterval seconds
    const interval = setInterval(fetchData, autoRefreshInterval * 1000)
    return () => clearInterval(interval);
  }, [scadaFilterValues])

  return (
    <Grid container >
      {mapOfPlantStatuses &&
      <>
        <Grid item xs={12} md={8} style={{ display: "flex" }}>
          <MapOfPlantStatuses value={mapOfPlantStatuses} />
        </Grid>
          <Grid item xs={12} md={4} style={{ display: "flex" }}>
            <PieChartOfPlantStatus value={mapOfPlantStatuses} />
          </Grid>
        </>
      }
      <Grid container style={{ borderRadius: "10px" }}>
        {currentAlarms &&
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <CountAlarmByLevelCharts value={currentAlarms} />
              </Grid>
              <Grid item xs={4}>
                <AlarmsTable value={currentAlarms}/>
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>
    </Grid>
  )
}