import { Box, Button } from "@mui/material"
import { TrackerType } from "../model/TrackerType"

interface Props {
  item: TrackerType
  onClick: CallableFunction
  isSelected: boolean
}

export function TrackerItem(props: Props) {
  return <Button
    sx={{
      width: { xs: '100%', sm: 140 },
      borderRadius: 5,
      border: 1,
      padding: 1,
      margin: 1,
      backgroundColor: props.isSelected ? '#e8f6dd' : 'inherit',
      ":hover": {
        backgroundColor: props.isSelected ? '#e8f6dd' : 'inherit',
      }
    }}
    onClick={() => props.onClick(props.item)}
  >
    <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
    >
      <Box
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        {props.item.label}
      </Box>
      <Box
        sx={{
          display: { xs: props.isSelected ? 'block' : 'none', sm: 'block' },
          maxWidth: 150,
          height: 100,
        }}
      >
        <img alt='tracker'
          style={{
            maxWidth: 111,
          }}
          src={props.item.imageUrl}
        />
      </Box>
    </Box>
  </Button>
}
