import { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import CustomNoRowsOverlay from '../../../../component/CustomNoRowsOverlay';
import AutocompletePlant from '../../../../component/form/AutocompletePlant';
import PlantCard from './plant/PlantCard';
import InformationCard from './information/InformationCard';
import MeterCard from './meter/MeterCard';
import TrackerCard from './tracker/TrackerCard';
import EssCard from './ess/EssCard';
import ImmersionHeaterCard from './immersionHeater/ImmersionHeaterCard';

import ToastService from '../../../../service/ToastService';

import apiV2 from '../../../../service/api/ApiV2';
import { AppDispatch, RootState } from "../../../../store/store";

import { Plant } from '../../../../interfaces/Plant';
import { PlantSearch } from '../../../../interfaces/PlantSearch';
import { ImmersionHeater } from '../../../../interfaces/ImmersionHeater'
import { Ess } from "../../../../interfaces/ess/Ess";
import { GetPlantObjects } from '../../../../service/GetPlantObjects';
import { fetchCheckpointsBlock, fetchCheckpointsExecuteAll, updateCheckpointResultState } from '../../../../store/control/checkpointsBlock.store';

import { CheckpointEquipmentTypeEnum } from '../../../../interfaces/control/Checkpoint';
import Mercure from '../../../../service/sse/Mercure';

export default function InstallationControlView() {
    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useDispatch<AppDispatch>();

    const [plantId, setPlantId] = useState<number | null>(null);
    const [plant, setPlant] = useState<Plant | null>(null);
    const [mercurePlantId, setMercurePlantId] = useState<number | null>(null);
    const [plantSearch, setPlantSearch] = useState<PlantSearch | null>(null);
    const [immersionHeaters, setImmersionHeaters] = useState<ImmersionHeater[]>([]);
    const [ess, setEss] = useState<Ess[]>([]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    const checkpointsBlocks = useSelector((state: RootState) => state.checkpointsBlock.data);

    const onPlantChange = (plantSearch: PlantSearch): void => {
        searchParams.set('plant_id', plantSearch.id.toString())
        setSearchParams(searchParams)
        setPlantSearch(plantSearch)
    }

    const handleExecuteAllCheckpoints = (): void => {
        if (plantId) {
            ToastService.success('Les points de contrôle sont lancés, veuillez patienter');
            dispatch(fetchCheckpointsExecuteAll(plantId))
            openMercure(plantId)
        }
    }
    const openMercure = useCallback((plantId: number) => {
        if (plantId !== mercurePlantId) {
            const mercure = Mercure.eventSource(plantId)
            mercure.onmessage = e => {
                const data = JSON.parse(e.data);
                dispatch(updateCheckpointResultState(data))
            }
            setMercurePlantId(plantId);
        }
    }, [dispatch, mercurePlantId]);

    useEffect(() => {
        if (searchParams.get('plant_id') !== null) {
            const plantId = parseInt(searchParams.get('plant_id')!);
            setPlantId(plantId);
            searchParams.delete('plant_id');

            if (plantId !== null) {
                apiV2.searchPlantById(plantId)
                .then(data => {
                    const plants = data;
                    setPlantSearch(plants[0]);
                })
            }
        }

        if (plantId) {
            /**
             * TODO: A supprimer au fur et à messure de l ajout des points de controles coté api
             */
            apiV2.plant(plantId).then(
                plant => {
                    setPlant(plant)
                    if (plant.operationDate !== null) {
                        openMercure(plant.id);
                        dispatch(fetchCheckpointsBlock(plant.id))
                        if (plant.id === plantId) {
                            GetPlantObjects(plantId)
                            .then(
                                element => {
                                    setImmersionHeaters(element.immersionHeaters.map((x) => { return x.immersionHeater }))
                                    setEss(element.ess.map((x) => { return x.ess }))
                                }
                            )
                        }
                    }
                }
            )
        }
    }, [plantId, dispatch, openMercure, mercurePlantId, searchParams])

    return (
        <Grid container sx={{ backgroundColor: 'transparent',}}>

            <Card className='search-card'>
                <Grid justifyContent="space-between" sx={{ backgroundColor: 'transparent'}}>
                    <Grid xs={12} sm={6} md={6} lg={3} item  style={{ marginTop: 15, paddingRight: (isMobile) ? '0px' : '20px' }}>
                        <AutocompletePlant value={plantId} onChange={onPlantChange} />
                    </Grid>
                </Grid>
            </Card>

            <Grid container sx={{ backgroundColor: 'transparent',  pl:0 , pr: 0 }}>
                {
                    ((plant))
                    ?
                    (
                        ((plant.operationDate !== null))
                        ?
                        <Grid item xs={12} >
                            <>
                                <PlantCard plantSearch={plantSearch}></PlantCard>

                                <Card className='custom-card' style={{ padding: 20, marginTop: 20}}>
                                    <div style={{textAlign: 'center'}}>
                                        <Button
                                            data-testid="executeAllCheckpointsButton"
                                            id="executeAllCheckpoints"
                                            onClick={handleExecuteAllCheckpoints}
                                            startIcon={<RestartAltIcon />}
                                            variant="outlined">
                                                Calculer tous les points de contrôle
                                        </Button>
                                    </div>
                                </Card>

                                {checkpointsBlocks.map((checkpointBlock, index) =>{
                                    /*
                                    * TODO: boucler sur les différents checkpointBlock.equipementType (TRACKER,METER...)
                                    */
                                    switch(checkpointBlock.equipmentType) {
                                        case CheckpointEquipmentTypeEnum.GENERAL: {
                                            return (<InformationCard key={`${plantId}-${index}`} checkpointBlock={checkpointBlock}></InformationCard>)
                                        }
                                        case CheckpointEquipmentTypeEnum.METER: {
                                            return (<MeterCard key={`${plantId}-${index}`} plant={plant} checkpointBlock={checkpointBlock}></MeterCard>)
                                        }
                                        case CheckpointEquipmentTypeEnum.TRACKER: {
                                            return (<TrackerCard key={`${plantId}-${index}`} checkpointBlock={checkpointBlock}></TrackerCard>)
                                        }
                                        default: {
                                            return (<></>);
                                        }
                                    }
                                })}

                                {
                                /**
                                 * A supprimer une fois que les checkpoints seront disponibles
                                 */
                                }
                                {immersionHeaters.map(immersionHeater => <ImmersionHeaterCard key={immersionHeater.id} immersionHeater={immersionHeater}></ImmersionHeaterCard>)}
                                {ess.map(essEntity => <EssCard key={essEntity.id} ess={essEntity}></EssCard>)}
                            </>
                        </Grid>
                        :
                        <Grid item xs={12} >
                            <Card data-cy="message" className='custom-card'>
                                <CustomNoRowsOverlay noRowText="Cette installation n'a pas encore été mise en service" loading={null}/>
                            </Card>
                        </Grid>
                    )
                    :
                    <Grid item xs={12} >
                        <Card className='custom-card'>
                            <CustomNoRowsOverlay noRowText="Effectuer une recherche pour contrôler une installation" loading={null}/>
                        </Card>
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}