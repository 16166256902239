import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'

export interface ScadaFilterValues {
  inAgencies: number[];
  notInPlantStatusTypes: string[];
  notSynchronised: boolean;
  startOperationDate: string | null;
  endOperationDate: string | null;
  savStatus: string | null;
  platform: string | null;
  plantTags: string[];
}

export interface ScadaFilterValuesState {
  value: ScadaFilterValues
}

const initialState: ScadaFilterValuesState = {
  value: {
    inAgencies: [],
    notInPlantStatusTypes: [],
    notSynchronised: true,
    startOperationDate: null,
    endOperationDate: null,
    savStatus: null,
    platform: null,
    plantTags: [],
  },
}

export const scadaFilterValuesSlice = createSlice({
  name: 'scadaFilterValues',
  initialState,
  reducers: {
    setInAgencies: (state, action) => {
      state.value.inAgencies = action.payload
    },
    setNotSynchronised: (state, action) => {
      state.value.notSynchronised = action.payload
    },
    setSavStatus: (state, action) => {
      state.value.savStatus = action.payload
    },
    removeNotInPlantStatusTypes: (state, action) => {
      state.value.notInPlantStatusTypes = state.value.notInPlantStatusTypes.filter(x => x !== action.payload)
    },
    addNotInPlantStatusTypes: (state, action) => {
      state.value.notInPlantStatusTypes.push(action.payload)
    },
    setStartOperationDate: (state, action) => {
      state.value.startOperationDate = action.payload
    },
    setEndOperationDate: (state, action) => {
      state.value.endOperationDate = action.payload
    },
    setPlatform: (state, action) => {
      state.value.platform = action.payload
    },
    setPlantTags: (state, action) => {
      state.value.plantTags = action.payload
    }
  }
})

export const {
  setInAgencies,
  setNotSynchronised,
  setSavStatus,
  removeNotInPlantStatusTypes,
  addNotInPlantStatusTypes,
  setStartOperationDate,
  setEndOperationDate,
  setPlatform,
  setPlantTags
} = scadaFilterValuesSlice.actions

export const selectFilterValues = (state: RootState) => state.scadaFilterValues.value

export default scadaFilterValuesSlice.reducer
