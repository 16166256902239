import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import ChangeDatesArrow from "../app/scada/plant/ChangeDatesArrow";
import {Direction} from "../constants/Direction";

import locale from 'antd/es/date-picker/locale/fr_FR';
import { DatePicker } from 'antd';

import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";

const { RangePicker } = DatePicker;

type RangePresetType = {
    label: string;
    value: [Dayjs, Dayjs];
};

type AppDatepickerProps = {
  value: [Dayjs | null, Dayjs | null] | null,
  onChange: (dates: [Dayjs | null, Dayjs | null]) => void;
  allowEmpty: [boolean, boolean];
  maxPeriodSelectable?: number; // max period in days that the user can select
  style?: React.CSSProperties;
  placeholder?: [string, string];
  label?: string;
  additionalRangePresets?: RangePresetType[];
  showArrows?: boolean;
}

const rangePresets: RangePresetType[] = [
    { label: 'Aujourd\'hui', value: [dayjs().startOf('day'), dayjs().startOf('day')] },
    { label: '2 derniers jours', value: [dayjs().add(-1, 'd').startOf('day'), dayjs().startOf('day')] },
    { label: '7 derniers jours', value: [dayjs().add(-6, 'd').startOf('day'), dayjs().startOf('day')] },
  ];

const AppDatepicker = (props: AppDatepickerProps) => {
  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null] | null>();


  useEffect(() => {
    setDates(props.value)
  }, [props] )

  const disabledDate = (current: Dayjs, info: { from?: Dayjs }) => {
    const isInFuture = current >= dayjs(); // disable futur dates
    if (info.from) {
      if (props.maxPeriodSelectable) return Math.abs(current.diff(info.from, 'days')) >= props.maxPeriodSelectable || isInFuture;
    }
    return isInFuture;
  };

  return (
      <Grid container direction={'row'} flexWrap={'nowrap'} gap={1}>
          {props.showArrows &&
              <Grid item>
                  <ChangeDatesArrow
                      id={"changeDatesArrowLeft"}
                      onChange={props.onChange}
                      dates={props.value !== null && props.value[0] && props.value[1] ? [dayjs(props.value[0]), dayjs(props.value[1])] : null}
                      direction={Direction.left}
                  />
              </Grid>
          }
          <Grid item>
              <Tooltip title={props.label} placement={'top'}>
                  <div>
                      <RangePicker
                          presets={rangePresets.concat(props.additionalRangePresets || [])}
                          placeholder={props.placeholder}
                          format="YYYY-MM-DD"
                          value={dates}
                          locale={locale}
                          allowEmpty={props.allowEmpty}
                          disabledDate={disabledDate}
                          style={props.style}
                          onCalendarChange={(event, newValue) => {
                              if(dates && props.maxPeriodSelectable){
                                  if(Math.abs(dayjs(newValue[0]).diff(dates[0], 'days')) >= props.maxPeriodSelectable){
                                      setDates([dayjs(newValue[0] , "YYYY-MM-DD"), null])
                                      return
                                  }
                              }
                              const startDate = newValue[0] ? dayjs(newValue[0] , "YYYY-MM-DD") : null
                              const endDate = newValue[1] ? dayjs(newValue[1] , "YYYY-MM-DD") : null

                              setDates([startDate, endDate])
                              if (startDate === null && !props.allowEmpty[0]) {
                                  return
                              }
                              if (endDate === null && !props.allowEmpty[1]) {
                                  return
                              }
                              props.onChange([startDate, endDate])
                          }}
                      />
                  </div>
              </Tooltip>
          </Grid>
          {props.showArrows &&
                <Grid item>
                    <ChangeDatesArrow
                        id={"changeDatesArrowRight"}
                        onChange={props.onChange}
                        dates={props.value !== null && props.value[0] && props.value[1] ? [dayjs(props.value[0]), dayjs(props.value[1])] : null}
                        direction={Direction.right}
                    />
                </Grid>
          }
      </Grid>
  );
}

export default AppDatepicker;
