import { Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import AppDatepicker from '../../../component/AppDatepicker';
import AutocompletePlant from '../../../component/form/AutocompletePlant';
import Card from '@mui/material/Card';
import { ChartArea } from './charts/ChartArea';
import dayjs, { Dayjs } from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import CardArea from './cards/CardArea';
import CustomNoRowsOverlay from '../../../component/CustomNoRowsOverlay';
import { GetPlantObjects } from '../../../service/GetPlantObjects';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setTrackers } from '../../../store/scada/tracker/trackers.store';
import { setPlantObjects } from '../../../store/scada/plant/PlantObjectsSlice';
import { checkDates } from './DateService';

import apiV2 from '../../../service/api/ApiV2';

import { PlantSearch } from '../../../interfaces/PlantSearch';
import { Tracker } from '../../../interfaces/Tracker'

const defaultChartDates: [Dayjs, Dayjs] = [dayjs().startOf('day').subtract(2, 'day'), dayjs().startOf('day')];

export default function PlantDataView() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const plantObjects = useAppSelector((state) => state.plantObjects.value)

    const onDatesChange = useCallback((dates: [Dayjs | null, Dayjs | null]) => {
        if (dates[0] !== null && dates[1] !== null) {
            searchParams.set('start_date', dates[0].format('YYYY-MM-DD'))
            searchParams.set('end_date', dates[1].format('YYYY-MM-DD'))
            setSearchParams(searchParams)
        }
    }, [searchParams, setSearchParams])

    function onPlantChange(plantSearch: PlantSearch): void {
        searchParams.set('plant_id', plantSearch.id.toString())
        setSearchParams(searchParams)
        // when plant is changed we reset dates to default
        onDatesChange([defaultChartDates[0], defaultChartDates[1]])
    }

    let startDate: Dayjs
    let endDate: Dayjs
    let datesInUrl: boolean = false
    if (!searchParams.get('start_date') || !searchParams.get('end_date')) {
        startDate = defaultChartDates[0]
        endDate = defaultChartDates[1]
    } else {
        datesInUrl = true;
        startDate = dayjs(searchParams.get('start_date'), 'YYYY-MM-DD');
        endDate = dayjs(searchParams.get('end_date'), 'YYYY-MM-DD');
    }
    const dates = checkDates(startDate, endDate) ? [startDate, endDate] as [Dayjs, Dayjs]: null

    let plantId: number | null = null
    if (searchParams.get('plant_id') !== null) {
        plantId = parseInt(searchParams.get('plant_id')!)
    }

    useEffect(() => {
        // when there are no dates in url put default dates in url
        if (!datesInUrl) onDatesChange([defaultChartDates[0], defaultChartDates[1]])
    }, [datesInUrl, onDatesChange])

    // get plant, trackers etc and put them inside store
    useEffect(() => {
    if (plantId) {
        apiV2.plant(plantId).then(
            plant => {
                // check that plant exists
                if (plant.id === plantId) {
                    GetPlantObjects(plantId)
                    .then(
                        x => {
                            const trackers: Tracker[] = []
                            dispatch(setPlantObjects(x))
                            x.trackers.forEach(element => {
                                trackers.push(element.tracker)
                            });
                            dispatch(setTrackers(trackers))
                        }
                    )
                } else {
                    dispatch(setPlantObjects(null))
                }
            })
    }
    }, [plantId, dispatch]);

    return (
        <Grid container sx={{ backgroundColor: 'transparent',  pl:0 , pr: 0 }}>
            <Card className='search-card'>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3} lg={3} style={{ marginTop: 15, paddingRight: (isMobile) ? '0px' : '20px' }}>
                        <AutocompletePlant value={plantId} onChange={onPlantChange} />
                    </Grid>
                    <Grid item style={{ marginTop: 15 }}>
                        <AppDatepicker value={dates} showArrows onChange={onDatesChange} allowEmpty={[false, false]} maxPeriodSelectable={7} style={{ height: 55, backgroundColor: "inherit", marginBottom: '20px' }}/>
                    </Grid>
                </Grid>
            </Card>

                {plantObjects === null &&
                <Card className='custom-card'>
                    <CustomNoRowsOverlay noRowText="Effectuer une recherche pour trouver une installation" loading={null}/>
                </Card>
                }
                {plantObjects !== null &&
                <Grid container sx={{ backgroundColor: 'transparent',  pl:0 , pr: 0 }}>
                    <Grid item xs={12} md={5} lg={3} style={{ paddingRight: (isMobile) ? '0px' : '20px'}}>
                        <CardArea/>
                    </Grid>
                    <Grid item xs={12} md={7} lg={9}>
                        <ChartArea/>
                    </Grid>
                </Grid>
                }
        </Grid>
    )
}