import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from "@mui/material/Typography";
import DataTableCheckpoint from '../DataTableCheckpoint'

import InputComment from '../InputComment'

import { CheckpointBlock } from '../../../../../interfaces/control/CheckpointBlock';

interface propsInterface {
    checkpointBlock: CheckpointBlock;
}

export default function InformationCard(props: propsInterface) {
    return (
        <Card data-testid="card" className='custom-card' style={{ marginTop: 20, paddingBottom: 10, paddingRight: 5, paddingLeft: 5 }}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    Informations générales
                </Typography>
                <DataTableCheckpoint data={props.checkpointBlock.checkpoints}></DataTableCheckpoint>
            </CardContent>
            <CardActions>
                <InputComment checkpointBlock={props.checkpointBlock}></InputComment>
            </CardActions>
        </Card>
    );
}