import { useEffect, useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Box, Button, FormControlLabel, FormGroup, FormHelperText, Grid, Link, Stack, Switch, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import Divider from '@mui/material/Divider'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import AutocompleteAddress from '../../../component/form/AutocompleteAddress'
import MapAddress, { handleApiLoadedArg } from '../../../component/form/MapAddress'
import { MatType } from './component/MatType'
import { RoughnessList } from './component/RoughnessList'
import { Roughness } from './model/Roughness';
import { ImplantationResponse, ImplantationRequest, PoleTypeEnum, TrackerTypeEnum, RougnessRequest } from './model';
import { apiImplantation } from '../../../service/api/ApiImplantation'
import Result from './Result'
import { TrackerList } from './component/TrackerList'
import { TrackerType } from './model/TrackerType'
import RoughnessDialog from './component/RoughnessDialog'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

export interface ImplantationData {
  address: string
  latitude: number
  longitude: number
  trackerType: "" | TrackerTypeEnum
  matType: PoleTypeEnum | null
  addraise: boolean
  sectors: Roughness | null[]
}

export default function Form() {
  const [selectedRoughness, setSelectedRoughness] = useState<Roughness | null>(null)

  const [sectors, setSectors] = useState<Array<Roughness | null>>([])
  const [gmaps, setGmaps] = useState<handleApiLoadedArg | null>(null)
  const [result, setResult] = useState<ImplantationResponse | null>(null)

  const selectedRoughnessRef = useRef<Roughness | null>(null)
  const sectorsRef = useRef<Array<Roughness | null>>([])
  const gmapsRef = useRef<handleApiLoadedArg | null>(null)
  const polygonsRef = useRef<google.maps.Polygon[]>([])

  const [roughnessDialogOpen, setRoughnessDialogOpen] = useState(false)

  useEffect(() => {
    resetSectors()
  }, []);

  useEffect(() => {
    selectedRoughnessRef.current = selectedRoughness;
  }, [selectedRoughness]);
  useEffect(() => {
    sectorsRef.current = sectors;
    validateSectors()
  }, [sectors]);
  useEffect(() => {
    gmapsRef.current = gmaps;
  }, [gmaps]);

  const getDefaultValues = () => {
    return {
      address: '',
      latitude: 48.078,
      longitude: -1.266,
      trackerType: '',
      matType: PoleTypeEnum.STANDARD,
      addraise: false,
      sectors: [],
    } satisfies ImplantationData
  }

  const { control, register, handleSubmit, watch, setValue, trigger, setError, clearErrors, reset, formState: { errors } } = useForm<ImplantationData>(
    {
      defaultValues: getDefaultValues()
    });

  const resetForm = () => {
    reset()

    setResult(null)
    resetSectors()
    clearPolygons()
    if (!gmapsRef.current) return
    drawCircle(gmapsRef.current, watch('latitude'), watch('longitude'))
  }

  const resetSectors = () => {
    const sectors = Array(12).fill(null)
    setSectors(sectors)
  }

  const handlePositionChange = ({ lat, lng }: { lat: number; lng: number }) => {
    setValue('latitude', lat)
    setValue('longitude', lng)
  }

  useEffect(() => {
    resetSectors()
    clearPolygons()

    if (!gmapsRef.current) return

    drawCircle(gmapsRef.current, watch('latitude'), watch('longitude'))
  }, [watch('latitude'), watch('longitude')])

  const onRoughnessSelected = (roughness: Roughness) => {
    setSelectedRoughness(roughness)
  }

  const onTrackerTypeSelected = (item: TrackerType) => {
    setValue('trackerType', item.value)
  }

  const validateSectors = (): boolean => {
    if (getSectorsNotNullSize() === 12) {
      clearErrors(`sectors`)
      return true
    }

    setError(`sectors`, {
      type: "manual",
      message: `Tous les secteurs doivent être renseignés`,
    })

    return false
  }

  const clearPolygons = () => {
    polygonsRef.current.forEach(polygon => {
      polygon.setMap(null) // Retire le polygone de la carte
    });
    polygonsRef.current = []; // Vide le tableau des polygones
  }

  const drawSector = (
    index: number,
    map: google.maps.Map,
    maps: typeof google.maps,
    center: { lat: number, lng: number },
    radius: number,
    startAngle: number,
    endAngle: number,
  ) => {
    const points = [];
    const numPoints = 30; // Nombre de points pour arrondir le secteur

    // Ajouter le centre du cercle comme premier point du polygone
    points.push(center);

    // Calcul des points sur la circonférence du cercle
    for (let i = 0; i <= numPoints; i++) {
      const angle = startAngle + (i * (endAngle - startAngle)) / numPoints;
      const point = {
        lat: center.lat + (radius * Math.cos(angle)) / 111320, // Conversion de mètres en latitude
        lng: center.lng + (radius * Math.sin(angle)) / (111320 * Math.cos(center.lat * (Math.PI / 180))), // Conversion de mètres en longitude
      };
      points.push(point);
    }

    const defaultFillColor = "#dddddd"

    // Créer un polygone pour le secteur
    const polygon = new maps.Polygon({
      paths: points,
      strokeColor: "#2fb109",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: defaultFillColor,
      fillOpacity: 0.50,
      map,
    });

    polygonsRef.current.push(polygon);

    polygon.addListener('click', () => {
      const roughness = selectedRoughnessRef.current

      if (!roughness) return

      const sectors = sectorsRef.current
      sectors[index] = (sectors[index] === roughness) ? null : roughness

      let newFillColor = defaultFillColor
      if (sectors[index]) {
        newFillColor = sectors[index].color
      }

      polygon.setOptions({
        fillColor: newFillColor,
      });

      setSectors([...sectors])
    })

    return polygon;
  }

  const handleApiLoaded = (gmaps: handleApiLoadedArg) => {
    setGmaps(gmaps)
    drawCircle(gmaps, 49, 49)
  }

  const drawCircle = (gmaps: handleApiLoadedArg, lat: number, lng: number) => {
    const center = { lat, lng }
    const radius = 300 // Rayon en mètres

    // Diviser le cercle en 12 secteurs égaux
    const numSectors = 12;
    const angleStep = (2 * Math.PI) / numSectors;

    for (let i = 0; i < numSectors; i++) {
      const startAngle = i * angleStep;
      const endAngle = (i + 1) * angleStep;
      drawSector(i, gmaps.map, gmaps.maps, center, radius, startAngle, endAngle);
    }
  }

  const getSectorsNotNullSize = (): number => {
    return Object.values(sectors).filter(value => value !== null).length;
  }

  const getSectorsSize = (): number => {
    return Object.keys(sectors).length;
  }

  const onSubmit: SubmitHandler<ImplantationData> = data => {
    if (validateSectors() === false) {
      return
    }

    const apiData = {
      latitude: data.latitude,
      longitude: data.longitude,
      tracker_type: data.trackerType as TrackerTypeEnum,
      pole_type: getPoleType(data),
      roughness_lengths: getRoughnessLengths(sectors as unknown as Roughness[])
    } satisfies ImplantationRequest

    apiImplantation.simulate(apiData).then((result) => {
      setResult(result)
    })
  }

  const getPoleType = (data: ImplantationData): PoleTypeEnum => {
    // lumioo est forcement standard
    if (data.trackerType === 'LUMIOO') {
      return PoleTypeEnum.STANDARD
    }

    if (data.addraise) {
      // ajout d'une rehausse
      if (data.matType === PoleTypeEnum.SHORTENED) {
        return PoleTypeEnum.SHORTENED_EXTENDED
      }
      return PoleTypeEnum.EXTENDED
    }

    return data.matType as PoleTypeEnum
  }

  const getRoughnessLengths = (roughnesses: Roughness[]): RougnessRequest[] => {
    return roughnesses.map(roughnessItem => ({
      z0: roughnessItem.z0,
      zmin: roughnessItem.zmin,
    }))
  }

  const hasErrors = Object.keys(errors).length > 0;

  return (
    <Box sx={{ width: { xs: "100%", lg: "1100px" }, }}>
      <Card style={{ marginTop: 20, borderRadius: '8px', boxShadow: 'none' }}>
        <CardContent>
          <Grid container justifyContent={"center"} direction={"row"}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <Divider textAlign="left">Quel modèle de tracker implanter ?</Divider>
              <Stack
                direction={{ sm: 'column', md: 'row' }}
              >
                <Box
                  padding={1}
                >
                  <FormControl
                    error={!!errors.trackerType}
                    sx={{ width: { xs: "100%", lg: "480px" }, }}
                  >
                    {errors.trackerType && (
                      <FormHelperText>
                        Veuillez sélectionner un modèle
                      </FormHelperText>
                    )}
                    <TrackerList
                      {...register("trackerType", { required: true })}
                      onClick={(item: TrackerType) => onTrackerTypeSelected(item)}
                    />
                  </FormControl>
                </Box>
                <Box
                  padding={1}
                >
                  <Typography variant="body2" color="text.primary" fontWeight={'bold'}>
                    Ajustement en hauteur du modèle
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    La hauteur de la structure est prise en compte dans le calcul des efforts de vent et de neige.
                  </Typography>
                  {watch(`trackerType`) !== 'LUMIOO' && (
                    <FormControl>
                      <MatType
                        name='matType'
                        error={!!errors.matType}
                        control={control}
                      />
                      <Controller
                        name="addraise"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FormControlLabel control={<Switch checked={value} onChange={onChange} />} label="Ajout d'une réhausse" />
                        )}
                      />
                    </FormControl>
                  )}
                  {watch(`trackerType`) === 'LUMIOO' && (
                    <Box>
                      <FormControl>
                        Aucun ajustement disponible pour ce modèle
                      </FormControl>
                    </Box>
                  )}
                </Box>
              </Stack>
              <FormControl fullWidth>
                <Divider textAlign="left">A quel endroit ?</Divider>
                <FormControl style={{ marginBottom: '0px', marginTop: 15 }} fullWidth>
                  <Controller
                    name={`address`}
                    control={control}
                    render={({ field }) => (
                      <AutocompleteAddress
                        {...field}
                        label="Adresse"
                        required={false}
                        preloadvalue={watch(`address`)}
                        register={register}
                        options={{ required: false }}
                        setValue={setValue}
                        trigger={trigger}
                        address={`address`}
                        latitude={`latitude`}
                        longitude={`longitude`}
                        error={!!errors.address}
                        latLngPrecision={3}
                        doNotLoadGoogleMapScript
                      />
                    )}
                  />
                  <Stack
                    direction="row"
                    alignItems="left"
                    spacing={2}
                  >
                    <FormGroup>
                      <TextField
                        required
                        type="number"
                        inputProps={{
                          step: "0.001"
                        }}
                        label="Latitude"
                        error={!!errors.latitude}
                        {...register("latitude", { required: true, valueAsNumber: true })}
                        helperText="3 chiffres après la virgule"
                        value={watch('latitude') || ''}
                      />
                    </FormGroup>
                    <FormGroup>
                      <TextField
                        required
                        type="number"
                        inputProps={{
                          step: "0.001"
                        }}
                        label="Longitude"
                        error={!!errors.longitude}
                        {...register("longitude", { required: true, valueAsNumber: true })}
                        helperText="3 chiffres après la virgule"
                        value={watch('longitude') || ''}
                      />
                    </FormGroup>
                  </Stack>
                  <Box
                    sx={{
                      paddingBottom: 1,
                    }}
                  >
                    <MapAddress
                      latitude={watch('latitude')}
                      longitude={watch('longitude')}
                      onChange={handlePositionChange}
                      precision={3}
                      zoom={16}
                      mapWidth='100%'
                      mapHeight='431px'
                      handleApiLoaded={(data: handleApiLoadedArg) => handleApiLoaded(data)}
                    />
                  </Box>
                  <Box>
                    <FormControl error={!!errors.sectors}>
                      {errors.sectors && (
                        <FormHelperText>
                          Nombre de secteurs saisis:
                          <span style={{
                            margin: 4,
                          }}>
                            {getSectorsNotNullSize()}
                          </span>
                          /
                          <span style={{
                            margin: 4,
                          }}>
                            {getSectorsSize()}
                          </span>
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Typography variant="body2" color="text.secondary">
                      Dans un rayon de 300m (le cercle sur la carte), sélectionnez les natures de sols présentes.<br />
                      Choississez une typologie de sol ci-dessous et cliquez sur les sections du cerle correspondantes.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      marginTop: 1,
                      display: 'flex',
                      justifyContent: 'space-around'
                    }}
                  >
                    <RoughnessList
                      selectedRoughness={selectedRoughness}
                      onClick={(roughness: Roughness) => onRoughnessSelected(roughness)}
                      onOpenDialog={() => setRoughnessDialogOpen(true)}
                    />
                  </Box>
                  <Box
                    sx={{
                      marginTop: 1,
                    }}
                  >
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => setRoughnessDialogOpen(true)}
                    >
                      <Stack alignItems="center" direction="row" gap={2}>
                        <HelpOutlineIcon />
                        <Typography variant="body1">Besoin d'aide pour choisir le bon type de sol ?</Typography>
                      </Stack>
                    </Link>
                  </Box>
                </FormControl>
                <FormControl error={hasErrors}>
                  <FormHelperText>Veuillez vérifier que tout les champs sont renseignés pour pouvoir lancer la vérification</FormHelperText>
                </FormControl>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    color: 'white'
                  }}
                >
                  <Box padding={1}>
                    <Button variant="outlined" type="submit">
                      Lancer la vérification
                    </Button>
                  </Box>
                  <Box padding={1}>
                    <Button
                      onClick={() => { resetForm() }}
                    >
                      Nouvelle étude
                    </Button>
                  </Box>
                </Box>
              </FormControl>
            </form>
          </Grid>
        </CardContent>
      </Card>
      {result &&
        <Card style={{ marginTop: 20, borderRadius: '8px', boxShadow: 'none' }}>
          <CardContent>
            <Result result={result} />
          </CardContent>
        </Card>
      }
      <RoughnessDialog
        id={1}
        open={roughnessDialogOpen}
        selectedRoughness={selectedRoughness}
        onClose={() => { setRoughnessDialogOpen(false) }}
        onSelect={(roughness: Roughness) => onRoughnessSelected(roughness)}
      />
    </Box>
  )
}
